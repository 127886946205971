import food01 from '../assets/images/food01.jpg';
import food02 from '../assets/images/food02.jpg';
import food03 from '../assets/images/food03.jpg';
import food04 from '../assets/images/food04.jpg';

const TileData = [
    {
      img: food01,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: food02,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: food03,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: food04,
      title: 'Image',
      author: 'author',
      cols: 1,
    },  
    
      
  ];

  export default TileData;