import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '90%',
    height: '230px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function LogCard(props) {
  const classes = useStyles();
  
  //console.log('PROPS', props);
  //console.log(props.data.trndate);
    

    if (typeof props.data === 'undefined' || props.data == null) {
        return(
            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.dmltype === 'DELETE' ? 'Record Deleted' : 'New Record'  }
                </Typography>        
                </CardContent>
            </Card>
        )
    } else {
        return (
    
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <Grid container spacing={1}>
                            <Grid item md={7}>
                                <span>Trn date</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.trndate}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Link id</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.link_id}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Ingredient id</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.ingredient_id}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Units</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.units}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Portion</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.portion}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Session</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.session}</span>
                            </Grid>
                            <Grid item md={7}>
                                <span>Locked</span>
                            </Grid>
                            <Grid item md={5}>
                                <span>{props.data.islocked}</span>
                            </Grid>
                        </Grid>                    
                    </Typography>
                </CardContent>
            </Card>
  );
}
}
