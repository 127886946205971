import React, {useState, useEffect} from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MealBuilder from './mealBuilder';
import Reset from '../components/svg/reset';
import * as Constants from '../config/constants';
import './searchbar.css';

const HeaderDiv = styled.div`
  display: flex;
  font-size: 1.25em;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SearchCard = styled(Card)`
    margin-bottom: 10px;
    width: 100%;
`;

const MealCard = styled(Card)`
    width: 100%;
`;

const FavouritesDiv = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 900; 
    color: green

`;

const SearchDiv = styled.div`
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 900; 
    color: green
`;


const MsgDiv = styled.div`
    font-size: 8px;
    font-weight: 900; 
    color: red;
    
`;



const CloneDiv = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    color: black;
    font-weight: 700; 
`;

const useStyles = makeStyles(( theme) => ({
    root: {
      flexGrow: 1,
    },
    form: {
        "& > *": {
            margin: theme.spacing(1),
            width: "9ch"
          },
        marginTop: 10  

    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing(0),
        width: '100%',
    },
    prep: {
        paddingLeft: 0,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        textAlign: 'left'
    },
    favselect: {
        width: '70%',
        marginLeft: '15px',
        },
    searchresult: {
        width: '70%',
        paddingTop: 5,
        marginLeft: '15px',
        marginBottom: '25px'
    },
    include: {
        width: '80%',
        backgroundColor: 'transparent'
    },
    exclude: {
        color: 'red',
        fontWeight: 700
    }
  }));


export default function Searchbar() {
    const classes = useStyles();
    const [results, setResults] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [current, setCurrent] = useState('test');
    const [isdirty, setIsdirty] = useState(false);
    const [sarg1, setSarg1] = useState('');
    const [sarg2, setSarg2] = useState('');
    const [sarg3, setSarg3] = useState('');
    const [sarg4, setSarg4] = useState('');
    const [sarg5, setSarg5] = useState('');
    const [linkid, setLinkid] = useState(0);
    const [isclonee, setIsclonee] = useState(false);

    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");


   
    const clearFields = () => {

       setSarg1('');
       setSarg2('');
       setSarg3('');
       setSarg4('');
       setSarg5('');
       setToggle1(false);
       setToggle2(false);
       setResults([]);
       setErrorMessage("");
       setLinkid(0);
    }
    
    const blurHandler = () => {
        alert("input blurred")
      }


    const handleSearchResults = (event) => {
        const id = event.target.id;
        const sarg = event.target.value;

        setIsdirty(true);

        switch(id){
            case 'search1':
                if(sarg.length > 6) {
                    setErrorMessage("Maximum length exceeded")
                }
                else   {
                    setSarg1(sarg);
                    setToggle1(true);
                } 
                break;
            case 'search2':
                if(sarg.length > 20) {
                    setErrorMessage("Maximum length exceeded")
                }
                else   {
                    setSarg2(sarg);
                    setToggle2(true);
                } 
                break;
            case 'search3':
                if(sarg.length > 20) {
                    setErrorMessage("Maximum length exceeded")
                }
                else   {
                    setSarg3(sarg);
                    setToggle2(true);
                } 
                break;
            case 'search4':
                if(sarg.length > 20) {
                    setErrorMessage("Maximum length exceeded")
                }
                else   {
                    setSarg4(sarg);
                    setToggle2(true);
                } 
                break; 
            case 'search5':
                if(sarg.length > 20) {
                    setErrorMessage("Maximum length exceeded")
                }
                else   {
                    setSarg5(sarg);
                    setToggle2(true);
                } 
                break;    
            default:
                console.log('Error on search criteria');   
        }
        
     };

     const HandleFavselect = (event) => {

        setLinkid(event.target.value);
        setIsclonee(true);

     }

     const HandleMealselect = (event) => {

        setLinkid(event.target.value);
        setIsclonee(true);
     }


     useEffect(() => {

        (async () => {
            const result = await axios.get(Constants.API + 'favourites/');
            var arr = [];
            arr = Object.values(result.data);
            setFavourites(arr[0]);



            setCurrent(arr[0].link_description);

        })();
      }, []);

     
    useEffect(() => {

        if(!isdirty)
            return;

        /*    
        if (sarg1.length < 1) {
            alert("RETURN");
            return;
        }
        */



        (async () => {
          const result = await axios.post(Constants.API + 'mealsearch/', 
          {
              sarg1:  sarg1,
              sarg2:  sarg2,
              sarg3:  sarg3,
              sarg4:  sarg4,
              sarg5:  sarg5,
          } );

          var arr = [];
          arr = Object.values(result.data);
          setResults(arr[0]);
          
        })();
    
    }, [sarg1, sarg2, sarg3, sarg4, sarg5, isdirty]);


  return (

    <React.Fragment>
    <CssBaseline />
    <Grid container spacing={0}>
        <SearchCard  variant="outlined">
            <HeaderDiv>Find a meal to clone or build from scratch</HeaderDiv>
        
        <Grid item md={12}> 
        <FavouritesDiv>
        Favourites:   
        <NativeSelect
            className={classes.favselect}
            //defaultValue={current}
            onChange={HandleFavselect}
            //displayEmpty
            placeholder='*** Favourites list ***'
            >
            {favourites.map(favourite => (
                <option
                    key={favourite.link_id}
                    value={favourite.link_id}
                >
                    {favourite.link_description}
                </option>
            ))}   
        </NativeSelect>  
        </FavouritesDiv>
        </Grid>
         
        <Grid item md={12}>
        <SearchDiv>
            Search:
        <form noValidate autoComplete="off" className={classes.form}>
            <TextField 
                id="search1" 
                value={sarg1} 
                color="primary"
                onChange={handleSearchResults} 
                margin={'dense'}
                type="search"
                disabled={toggle2}
                variant="outlined"
                label='Link id'
                //error={sarg1.length = 6}
                inputProps = {{className:classes.include}} 
                style={{ width: 100 }}
            />    
            <TextField 
                id="search2" 
                value={sarg2} 
                onChange={handleSearchResults} 
                margin={'dense'}
                type="search" 
                disabled={toggle1}
                placeholder='arg 1'
                inputProps = {{className:classes.include}}
            />               
            <TextField 
                id="search3" 
                value={sarg3} 
                onChange={handleSearchResults} 
                margin={'dense'}
                type="search" 
                disabled={toggle1}
                placeholder='arg 2'
                inputProps = {{className:classes.include}}
            />               
            <TextField 
                id="search4" 
                value={sarg4} 
                onChange={handleSearchResults} 
                margin={'dense'}
                type="search" 
                disabled={toggle1}
                placeholder='arg 3'
                inputProps = {{className:classes.include}}
            />
            <TextField 
                id="search5" 
                value={sarg5} 
                onChange={handleSearchResults} 
                margin={'dense'}
                type="search" 
                disabled={toggle1}
                placeholder='excl. arg'
                inputProps = {{className:classes.exclude}}
            />
            <Tooltip title="clear search fields" placement="top-start">
            <span>
              <IconButton
              onClick={clearFields}>
                <Reset fill='orange' />  
            </IconButton>
            </span>
          </Tooltip>  
        </form>
        <MsgDiv>
            {errorMessage}
        </MsgDiv>
        
            Search results:
        <Select
            //native
            onChange={HandleMealselect}
            className={classes.searchresult}
            displayEmpty
            label="Search results"
            >
            {results.map(result => (
                <option
                    key={result.link_id}
                    value={result.link_id}
                >
                    {result.meal_description}
                </option>
            ))}   
        </Select> 
        </SearchDiv>
        </Grid>
        </SearchCard>
        <MealCard  variant="outlined">
        <CloneDiv>    
            <Grid item md={12}>
                <Box className={classes.prep}>
                    Clone and edit
                </Box>        
            </Grid>
            </CloneDiv>
            <Grid item md={12}>
                <MealBuilder val={linkid} isclonee={isclonee} />
            </Grid>
        
        </MealCard>
    </Grid>    
    </React.Fragment>
  )



}  