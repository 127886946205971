import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import * as Constants from '../config/constants';
import LogCard from './logcard';

const theme = createMuiTheme({
    
  });

const useStyles = makeStyles({
    root: {
      width: '100%',
      fontSize: 12,
      paddingTop: 20,
      paddingBottom: 30,
      paddingLeft: 10,
    },
    label: {
      fontWeight: '800'  
    },
    maindiv: {
        '& > *': {
          margin: theme.spacing(2),
        }
    }   

});


export default function AuditLogs() {

    const classes = useStyles();

    const [trns, setTrns] = useState([]);
    const [trnid, setTrnid] = useState();
    const [dmltype, setDmltype] = useState();
    const [dmltimestamp, setDmltimestamp] = useState();
    const [dmlcreatedby, setDmlcreatedby] = useState();
    const [olddata, setOlddata] = useState();
    const [newdata, setNewdata] = useState();

    useEffect(() => {

        (async () => {

            const result 
                = await axios.get(Constants.API + 'loadloggedtrnids/');
            setTrns(result.data);
        })();  
    }, []);

    useEffect(() => {

        if(trnid === undefined){
            return;
        }

        (async () => {

            const result 
                = await axios.get(Constants.API + 'loadlogdatabytrnid/' + trnid);
            
            setDmltype(result.data[0].dml_type);
            setDmltimestamp(result.data[0].dml_timestamp);
            setDmlcreatedby(result.data[0].dml_created_by);

            console.log(result.data[0].old_row_data);

            const json_old = result.data[0].old_row_data;
            const oldrow = JSON.parse(json_old);

            const json_new = result.data[0].new_row_data;
            const newrow = JSON.parse(json_new);

            setOlddata(oldrow);
            setNewdata(newrow);

        })();    
    
    }, [trnid]);

    const HandleTrnSelection = (event) => {

        setTrnid(event.target.value);
    } 
    
    const ClearLogs = () => {

        axios.delete(Constants.API + 'cleartrnauditlog')
        .catch(err => {
            // Handle Error Here
            console.error(err);
        });


    }

    return(
        <React.Fragment>
            <Typography component="div" style={{ backgroundColor: 'transparent' }} >
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                        <div className={classes.maindiv}>
                        <TextField
                            id="trnid"
                            select
                            label="Transaction id"
                            onChange={HandleTrnSelection}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Select a transaction id"
                            variant="outlined"
                        >
                            {trns.map((option) => (
                                <option key={option.trn_id} value={option.trn_id}>
                                    {option.trn_id}
                                </option>
                            ))}
                        </TextField> 
                        <Button 
                            variant="contained" 
                            color="secondary"
                            size="small"
                            onClick={ClearLogs}
                        >
                            Clear Logs
                        </Button>        
                        </div>    
                        </Grid> 
                        <div className={classes.root}>
                            <span className={classes.label}>Type:&nbsp;</span> {dmltype} &nbsp; 
                            <span className={classes.label}>Timestamp:&nbsp;</span> {dmltimestamp} &nbsp;
                            <span className={classes.label}>User: &nbsp;</span>{dmlcreatedby} &nbsp;
                        </div>
                        <Grid item md={6}>
                            <span className={classes.label}>Old values</span>
                            <LogCard dmltype={dmltype} data={olddata}/>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.label}>New values</span>
                            <LogCard dmltype={dmltype} data={newdata}/>
                        </Grid>
                    </Grid>
            </Typography>  
        </React.Fragment>
    )
}