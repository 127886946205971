import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns/';
import toDate from 'date-fns/toDate'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import MoveToTopIcon from "@material-ui/icons/SwapVerticalCircleOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import * as Constants from '../config/constants';
import CustomTooltip from './common/customTooltip';
import './intaketables.css';

const colsweek =  [
  
  { headerName: 'Trn Date', field: 'trn_date', width: "105", cellStyle: {fontSize: '10px', fontWeight: '700'} },
  { headerName: 'Cals', field: 'calories', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Prot', field: 'protein', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Fat', field: 'fat', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Sats', field: 'saturates', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Carbs', field: 'carbohydrates', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Sugar', field: 'sugars', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Fibre', field: 'fibre', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
  { headerName: 'Salt', field: 'salt', width: "90", cellStyle: {fontSize: '10px', textAlign: 'right'}, type:'numericColumn'},
];

class BtnCellRenderer extends React.Component {
  constructor(props) {
      super();
      this.btnClickedHandler = this.btnClickedHandler.bind(this);

      console.log('btn ', {...props});
  }
  btnClickedHandler() {
      this.props.clicked(this.props.value);
      
  }
  render() {
      return (
          
          <Tooltip title="Delete" placement="top-start">
              <span>
                  <IconButton
                      onClick={this.btnClickedHandler}
                      disabled={this.props.data.islocked }
                      size='small'
                      color='secondary'

                  >
                      <DeleteIcon /> 
                  </IconButton>
              </span>
          </Tooltip> 
    )
  }
}

class BtnCellRenderer2 extends React.Component {
  constructor(props) {
      super();
      this.btnClickedHandler = this.btnClickedHandler.bind(this);

      console.log('btn ', {...props});
  }
  btnClickedHandler() {
      this.props.clicked(this.props.value);
      
  }
  render() {
      return (
          
          <Tooltip title="Move to top" placement="top-start">
              <span>
                  <IconButton
                      onClick={this.btnClickedHandler}
                      disabled={this.props.data.islocked }
                      size='small'
                      color='primary'

                  >
                      <MoveToTopIcon /> 
                  </IconButton>
              </span>
          </Tooltip> 
    )
  }
}

class EditableCell extends React.Component {
  constructor(props) {
      super();

      console.log('cell ', {...props});
  }
  
  render() {
    if(this.props.colDef.field === 'portion')
      return(
        this.props.data.islocked 
          ? <div id='locked'>{this.props.data.portion}</div>
          : <div id='unlocked'>{this.props.data.portion}</div>
      ) 
    else if(this.props.colDef.field === 'description') 
      return(
        this.props.data.islocked 
          ? <div id='locked'>{this.props.data.description}</div>
          : <div id='unlocked'>{this.props.data.description}</div>
      )  
    else if(this.props.colDef.field === 'units2') 
      return(
        this.props.data.islocked 
          ? <div id='locked'>{this.props.data.units2}</div>
          : <div id='unlocked'>{this.props.data.units2}</div>
      )  
    else 
      return (
        this.props.data.islocked 
          ? <div id='locked2'>{this.props.data.ingredient_id}</div>
          : <div id='unlocked2'>{this.props.data.ingredient_id}</div>
      )    
  }
  
}  

export default function IntakeTables(props) {

    const [dataWk, setDataWk] = useState([]);
    const [dataDtl, setDataDtl] = useState([]);
    const [dataSum, setDataSum] = useState([]);
    const [columnDefsWeek, setColumnDefWeek] = useState(colsweek);
    const [reportDate, setReportDate] = useState();
    const [currLinkid, setCurrLinkid] = useState();
    const [trnsById, setTrnsById] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isVisWk, setIsVisWk] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);
    const [message, setMessage] = useState('');
    
    const [status, setStatus] = useState();
    const [warning, setWarning] = useState(false);
    const [custom, setCustom] = useState(
        {
          deletebtn: BtnCellRenderer,
          movetotopbtn: BtnCellRenderer2,
          customTooltip: CustomTooltip,
          editablecell: EditableCell,  
        }
      );              
    const [agtheme, setAgtheme] = ('ag-theme-alpine');
   
    useEffect(() => {

      var reportdate;

      if (reportDate === undefined) {
        reportdate = toDate(Date.now());
        reportdate = format(reportdate, 'yyyy-MM-dd');
      }
      else {
        reportdate = format(reportDate, 'yyyy-MM-dd');
      }

        (async () => {

            const result = await axios(Constants.API + 'intake2/' + reportdate );
            
            setDataWk(result.data[0]);
            setDataDtl(result.data[1]);
            setDataSum(result.data[2]);

            if(result.data[2].length > 0){
              setCurrLinkid(result.data[2][0].link_id);
            }
            else
              setCurrLinkid(0);
              
            setRefresh(false);
            
          })();  
      
    
      }, [reportDate, refresh]);


    useEffect(() => {

        FetchTrnsById();
  
    }, [currLinkid]);  
    
    const Update = (event) => {

      if (event.colDef.field === 'units2')
        UpdateUnits(event);  
      else if (event.colDef.field === 'ingredient_id')    
        UpdateIngredient(event);
      else if (event.colDef.field === 'portion')  
        UpdatePortion(event)
      else if (event.colDef.field === 'description')     
        UpdateMealDescription(event)
    }

    const UpdateIngredient = (event) => {

      var trnid = event.node.data.trn_id;
      var newval = event.newValue;

      axios.put(Constants.API + 'updateingredient/', 
        {
          trnid:  trnid,
          newid: newval,
        }
      )
      .then((response) => {

      setRefresh(true);
      setMessage('Transaction ' + trnid + ' ingredient changed to ' + newval);
      },
        (error) => {
        console.log(error);
        //setMessage(error);
        //setWarning(true);
      });

    }
    
    const UpdateUnits = (event) => {

      var trnid = event.node.data.trn_id;
      var newqty = event.newValue;
        
      axios.put(Constants.API + 'updatetrnidqty/', 
        {
          trnid:  trnid,
          qty: newqty,
        }
      )
      .then((response) => {

      setRefresh(true);
      setMessage('Transaction ' + trnid + ' quantity changed to ' + newqty);
      },
        (error) => {
        console.log(error);
      });

    };

    const UpdateMealDescription = (event) => {

      var linkId = event.node.data.link_id;
      var newDescription = event.newValue;

      axios.put(Constants.API + 'updateMealDescription/', 
        {
          linkid:  linkId,
          newDescription: newDescription,
        }
      )
      .then((response) => {

      setRefresh(true);
      setMessage('Link ' + linkId + ' description changed to ' + newDescription);
      },
        (error) => {
        console.log(error);
      });

    };  



    const UpdatePortion = (event) => {

      var linkid = event.node.data.link_id;
      var newportion = event.newValue;

      axios.put(Constants.API + 'updatelinkidportion/', 
        {
          linkid:  linkid,
          portion: newportion,
        }
      )
      .then((response) => {

      setRefresh(true);
      setMessage('Link ' + linkid + ' portion changed to ' + newportion);
      },
        (error) => {
        console.log(error);
      });

    };  

    const DeleteLinkid = (field) => {

        axios.delete(Constants.API + 'deletelinkid/' + field)
        .then((response) => {
            setRefresh(true);
            setMessage('Linkid ' + field + ' deleted.');
          },
          (error) => {
            setMessage('Error on delete - check log')
          });

    }
    

    const MoveToTop = (field) => {

        axios.post(Constants.API + 'movetotop/', 
          {
            linkid:  field,
          }
        )
        .then((response) => {
          setMessage(field + ' moved to top successfully.');
          setWarning(false);

          setRefresh(true);

          },
          (error) => {
            setMessage('Move failed - see log for details');
            setWarning(true);
          });
    
    }

    const DeleteTrnid = (field) => {

        axios.post(Constants.API + 'deletetrn/', 
        {
            trnid: field,
            linkid: null,
        }
        )
            .then((response) => {

            setMessage('Transaction ' + field + ' deleted.');
            setRefresh(true);
        },
            (error) => {
            setMessage('Failed to delete transaction ' + field);
        });
        

    }

    
    const SetSelectedNode = (event) => {
        setCurrLinkid(event.node.data.link_id);
    }  
    
   
    const FetchTrnsById = () => {

      const trnsById = dataDtl.filter(trn => trn.link_id == currLinkid);

      setTrnsById(trnsById);
        

    }

    const HandleDate = (date) => {
      setReportDate(date);
    };

    const handleToggleWeek = (event) => {

      setIsVisWk(event.target.checked);
    }

    const handleToggleEdit = (event) => {

      setAllowEdit(event.target.checked);

      allowEdit ? setMessage('') : setMessage('Edit mode');
    }

    
    

    return (
      <React.Fragment>
      
        <Typography component="div" 
          style={{ backgroundColor: 'transparent' }} >
        <div  className='datediv'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>  
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="dense"
            id="datepicker"
            autoOk={true}
            disableFuture={false}
            value={reportDate}
            onChange={HandleDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              }}
          />
        </MuiPickersUtilsProvider>
          <FormGroup row>
            <FormControlLabel
              id="showtotals"
              control={<Checkbox checked={isVisWk} 
                onChange={handleToggleWeek} 
                name="checkedA" />}
              label="Show last 7 days totals"
            />
          </FormGroup>
        </div>
        <Grid container spacing={1}></Grid>
        <Grid item xs={12}>
          <div id='tabdiv'>
          <div  className='container'>
            {isVisWk ?
            <div className={agtheme} style={ { paddingTop: 5, height: 300, width: "100%" } }>
              <AgGridReact 
                columnDefs={columnDefsWeek} 
                id="weekGrid"
                cell-horizontal-padding={0}
                rowSelection="single"
                rowData={dataWk}
              />
          </div>    
            : null}
          
          <span>Summary intake for selected date (Move-to-top only possible for current date)</span>
          <div class='ag-theme' className='ag-theme' style={ { paddingTop: 5, paddingRight: 10, height: 300, width: "100%" } }>
            <AgGridReact 
              onCellValueChanged={Update}
              onCellClicked={SetSelectedNode}
              id="summaryGrid"
              rowSelection="single"
              rowData={dataSum}
              tooltipShowDelay= {0}
              frameworkComponents={custom}
            >
              <AgGridColumn
                width={50}
                headerName=""
                cellRendererParams= {
                    {clicked:function(field)  {
                      DeleteLinkid(field);
                    }}
                  }
                cellRenderer = 'deletebtn'
                field="link_id"
              />
              <AgGridColumn
                width={50}
                headerName=""
                cellRendererParams= {
                    {clicked:function(field)  {
                      MoveToTop(field);
                    }}
                  }
                cellRenderer = 'movetotopbtn'
                field="link_id"
              />
              <AgGridColumn
                headerName="Lnk"
                width={60}
                field="link_id"
                cellStyle= {{ color: 'blue', fontSize: '12px', fontWeight: '900' }}
              />
              <AgGridColumn
                headerName="Meal Description"
                width={145}
                field="description"
                tooltipField= 'description'
                tooltipComponent= 'customTooltip'
                cellStyle= {{ fontSize: '12px' }}
                editable={function(params) {
                  return !params.node.data.islocked
                }}
                cellRenderer = 'editablecell' 
                tooltipComponentParams= {{ }}
              />
              <AgGridColumn
                headerName="Session"
                width={60}
                field="session"
                cellStyle= {{fontSize: '12px', textAlign: 'center'}}
              />
              <AgGridColumn
                headerName="Ptn"
                width={40}
                field="portion"
                editable={function(params) {
                  return !params.node.data.islocked
                }}
                cellRenderer = 'editablecell' 
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Cals"
                width={60}
                field="calories"
                cellClassRules={{
                  'rag-green': 'x < 200',
                  'rag-amber': 'x >= 200 && x < 500',
                  'rag-red': 'x >= 500',
                }}
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Prot"
                width={60}
                field="protein"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Fat"
                width={60}
                field="fat"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Sats"
                width={60}
                field="saturates"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Carb"
                width={60}
                field="carbohydrates"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Sug"
                width={60}
                field="sugars"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Fibre"
                width={60}
                field="fibre"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Salt"
                width={60}
                field="salt"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
            </AgGridReact>  
          </div>
          
          <span>Ingredients for selected meal:</span>
          <div className={agtheme} style={ { paddingTop: 5, paddingRight: 2, height: 200, width: "100%" } }>
            <AgGridReact 
              onCellValueChanged={Update}
              id="detailGrid"
              cell-horizontal-padding={0}
              rowSelection="single"
              rowData={trnsById}
              frameworkComponents={custom}
            >
              <AgGridColumn
                width={70}
                headerName=""
                cellRendererParams= {
                    {clicked:function(field)  {
                        DeleteTrnid(field);
                    }}
                  }
                cellRenderer = 'deletebtn'
                field="trn_id"
              />
              <AgGridColumn
                headerName="Trn"
                width={70}
                field="trn_id"
                cellStyle= {{fontSize: '12px'}}
              />
              <AgGridColumn
                headerName="Lnk"
                width={70}
                field="link_id"
                cellStyle= {{fontSize: '12px', color: 'blue', fontWeight: '900'}}
              />
              <AgGridColumn
                headerName="Id"
                width={70}
                field="ingredient_id"
                sortable={true}
                cellStyle= {{fontSize: '12px'}}
                editable={function(params) {
                  return !params.node.data.islocked
                }}
                cellRenderer = 'editablecell'
                 
              />
              <AgGridColumn
                headerName="Ingredient"
                width={190}
                field="description"
                cellStyle= {{ fontSize: '12px'}}
              />
              <AgGridColumn
                headerName="Brand"
                width={100}
                field="brand"
                cellStyle= {{ fontSize: '12px'}}
              />
              <AgGridColumn
                headerName="UOM"
                width={70}
                field="uom"
                cellStyle= {{fontSize: '12px', textAlign: 'center'}}
              />
              <AgGridColumn
                headerName="Unit"
                width={70}
                field="units"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Ptn"
                width={70}
                field="portion"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Qty"
                width={70}
                field="units2"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                editable={function(params) {
                  return !params.node.data.islocked
                }}
                cellRenderer = 'editablecell'  
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Cals"
                width={60}
                field="calories"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Prot"
                width={60}
                field="protein"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Fat"
                width={60}
                field="fat"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Sats"
                width={60}
                field="saturates"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Carbs"
                width={60}
                field="carbohydrates"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Suger"
                width={60}
                field="sugars"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Fibre"
                width={60}
                field="fibre"
                cellStyle= {{fontSize: '12px', textAlign: 'right'}}
                type={'numericColumn'}
              />
              <AgGridColumn
                headerName="Salt"
                width={60}
                field="salt"
                cellStyle= {{fontSize: '12px', textAlign: 'right', paddingRight: '10px'}}
                type={'numericColumn'}
              />
            </AgGridReact>  
          </div>
        </div>
        </div>
      </Grid>
      
      <Grid item xs={12}>
        <span id="msgspan">{message}</span>
      </Grid>
    
    
    </Typography>
    
    </React.Fragment>
    )
}


/*

<Grid item xs={4}>
          <input 
            type="date" 
            name="test" 
            className='dateinput'
            id="dateid"
            ref={textInput}
            //onChange={HandleDate}
            >
          </input>
        </Grid>


        */