import React from 'react';
import { Auth0Provider, loading } from './auth/react-auth0-spa';
import auth0Config from './config/auth0.config.json';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(

  <React.StrictMode>
    <Auth0Provider
            domain={auth0Config.domain}
            client_id={auth0Config.clientId}
            audience={auth0Config.audience}
            redirect_uri={window.location.origin}
    >
  
      <App />,
  
    </Auth0Provider> 
  
  </React.StrictMode>,  
    
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// "clientId": "qCSAbsBDrRLbzgRS683vTP6LhkQjqYAd",
