import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import * as Constants from '../config/constants';
import TimeRenderer from './common/timeRenderer';
import InfoPanel from './infopanel';

export default function MaintainWeightRecord() {

    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState(false);
    const [status, setStatus] = useState();
    const [dateid, setDateid] = useState();
    const [weighttime, setWeighttime] = useState('00:00:00');
    const [weight, setWeight] = useState(0.0);
    const [doupdate, setDoupdate] = useState(false);

    

    const [frameworkcomponents, setTimerenderer] 
            = useState({timerenderer: TimeRenderer});

    useEffect(() => {

        (async () => {

            const result = await axios(Constants.API + 'weight');
            var arr = [];
            arr = Object.values(result.data);
            setData(arr[0]);

            
          })();  
      
    
      }, []);


    const handleChange = (event) => {
        var col = event.column.colId;
        var date = event.node.data.date_id;
        var newvalue = event.newValue;
        
        setDoupdate(false);
        setDateid(date);
        if (col=='weight_time')
            if (/^(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])/.test(newvalue)) {
                setWeighttime(newvalue);
                setDoupdate(true);
            }
            else {
                setMessage('Wrong time format. Should be nn:nn:nn');  
            }
        if (col=='weight')
            if (/^(\d*\.)?\d+$/.test(newvalue)) {
                setWeight(newvalue); 
                setDoupdate(true);
                
            }
            else {
                setMessage('Wrong weight format. Should be a decimal');
                setWarning(true);  
                   
            }           
    };


    useEffect(() => {
        
       if(doupdate)
            UpdateWeightRecord(); 

    }, [doupdate, weighttime, weight]);  
    
    const UpdateWeightRecord = () => {
        
        setMessage('Weight record updated');
        setWarning(false);

        (async () => {
            const result = await axios.post(Constants.API + 'amendweight/', 
            {
                date:  dateid,
                weight:  weight,
                
            } );
    
        })();    
    }

    return (
        <div className="ag-theme" style={ { height: 300, width: 350 } }>
            <h5>    
                <ul> 
                    <li>Amend weight for current date.</li>
                    <li>This is optional.</li>
                    <li>00:00:00 indicates no new weight recorded.</li>
                </ul>
            </h5>
        <AgGridReact
            rowData={data}
            frameworkComponents={frameworkcomponents}
            onCellValueChanged={handleChange}
            getRowStyle={function(params) {
                if (params.node.rowIndex === 0) {
                    return { 
                        backgroundColor: 'lightgray'
                        ,color: 'black' 
                        ,fontWeight: '800'
                        
                    };
                }
                
            }}
            rowStyle= {{ fontSize: '14px',paddingLeft: '0' }}  
              
            >

            <AgGridColumn 
                headerName="Date"
                width={120}
                field="date_id"
                
            >

            </AgGridColumn>
            <AgGridColumn 
                headerName="Time"
                width={100}
                field="weight_time"
                    
            >

            </AgGridColumn>
            <AgGridColumn 
                headerName="Weight"
                width={100}
                field="weight"
                editable= {function(params) {
                    if (params.node.data.islocked==0)
                    return true;
                }}
                
            >

            </AgGridColumn>
        </AgGridReact>
        <InfoPanel msg={message} warning={warning} status={status}/>
        </div>
    );

}