import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components'

import { lightTheme, darkTheme } from "../themes/Themes"

import Brands from './brands';
import Searchbar from './searchbar';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      }
  }));

export default function Kitchen() {
    const classes = useStyles();
    const theme = useTheme();
    const palletType = "dark";
    
    const darkTheme = createMuiTheme({
      palette: {
        type: palletType,
    }
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Typography component="div" 
          style={{ backgroundColor: 'transparent' }} >
      <Container maxWidth="md">
        
        <Grid container spacing={1}>
            <Grid item xs={4}>
              <Brands />
            </Grid>
            <Grid item xs={8}>
              <Searchbar />
            </Grid>
        </Grid>
        </Container>
        </Typography>  
      
      </ThemeProvider>
    </React.Fragment>
  );
}


