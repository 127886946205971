import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';

import MaintainIngredients from "./maintainIngredients";
import MaintainBrands from "./maintainBrands";
import MaintainWeightRecord from "./maintainWeightRecord";
import MaintainUOM from "./maintainUOM";
import IngredientUsage from "./ingredientusage";
import UnlockTrn from "./unlockTrn";
import AuditLogs from "./auditLogs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    fontSize: 20,
    fontWeight: 700
  },
  btn: {
    size: 'small',
    paddingTop: 0,
  },
}));    

export default function Maintenance() {

  const classes = useStyles();

  const [value, setValue] = React.useState('ingredients');

  const HandleSelection = (event) => {
    setValue(event.target.value);
  }

  const Switch2 = () => {

    if (value == 'ingredients') 
      return(<MaintainIngredients />)
    else if (value == 'brands')
      return(<MaintainBrands />)
    else
      return(<MaintainWeightRecord />)
    
  }

  const Switch = () => {

    switch(value){
      case 'ingredients':
        return(<MaintainIngredients />)
      case 'brands' :
        return(<MaintainBrands />) 
      case 'weight':
        return(<MaintainWeightRecord />)
      case 'uom':
        return(<MaintainUOM />)
      case 'usage':
        return(<IngredientUsage />)  
      case 'unlocktrn':
        return(<UnlockTrn />) 
      case 'audit':
        return(<AuditLogs />)           
    }    
  }
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md"  >
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '70vh' }} >
          <Grid  container spacing={0}>
          <Grid  item xs={12}>
              <h3>Reference Data Maintenance</h3> 
            </Grid>
            <Grid  item xs={5}>
              <FormControl component="fieldset" >
                <FormLabel component="legend">(Master privileges required)</FormLabel>
                  <RadioGroup className={classes.root} aria-label="maintenance" name="maint1" value={value} onChange={HandleSelection}>
                    <FormControlLabel value="ingredients" control={<Radio />} label="Ingredients" />
                    <FormControlLabel value="brands" control={<Radio />} label="Brands" />
                    <FormControlLabel value="weight" control={<Radio />} label="Weight" />
                    <FormControlLabel value="usage" control={<Radio />} label="Ingredient Usage" />
                    <FormControlLabel value="uom" control={<Radio />} label="UOM" />
                    <FormControlLabel value="unlocktrn" control={<Radio />} label="Unlock a transaction set" />
                    {/*<FormControlLabel value="audit" control={<Radio />} label="Audit Logs" /> */ }
                  </RadioGroup>
              </FormControl>
            </Grid> 
            <Grid  item xs={7}>
              {Switch()}   
            </Grid> 
          </Grid>   
        </Typography>  
      </Container>
    </React.Fragment>
  );
}