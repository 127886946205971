
//192.168.1.233
//export const API = 'http://192.168.0.13:8080/';

//export const API = 'http://thegoodbyte.net:8080/';

//export const API = 'https://199.195.116.61:8443';

export const API = 'https://thegoodbyte.net:8443/';

export const DB = 'nutrak22';

export const VER = '1.3.1';