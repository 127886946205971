import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.open);

    }, [props]);  

  const handleClose = (e) => {
      
    setOpen(false);
    props.callbackfunc(e);
  };

  return (
    <div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New ingredient selected"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to create a new meal or add the ingredient 
            to the existing meal.
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            New meal
          </Button>
          <Button id="no meal" onClick={() => handleClose(false)} color="primary" autoFocus>
            Add ingredient
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}