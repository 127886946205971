import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';


const IDDiv = styled.div`
    fontSize: 14,
    color: red

`;

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'scroll',
        minWidth: '100%',
        tableLayout: "fixed",
        backgroundColor: 'transparent',
        //height: '250px',
        border: 'none'
      },
    id: {
        fontWeight: 800,
        color: 'orange',
        fontSize: 18,
        display: 'fixed',
        textAlign: 'left'
    },  
    header: {
        overflowY: 'auto',
        fontWeight: 800,
        fontSize: 12,
        display: 'fixed',
        height: '60px',
        textAlign: 'left'
    },
    labels: {
        maxWidth: '75%',
        minWidth: '75%',
        fontSize: 10,
        fontWeight: 600,
        textAlign: 'left'
    },
    brand: {
        maxWidth: '75%',
        minWidth: '75%',
        fontSize: 10,
        fontWeight: 600,
        textAlign: 'left',
        marginBottom: 2
    },
    values: {
        width: '25%',
        fontSize: 10,
        fontWeight: 500,
        textAlign: 'right'
    }
    
  }));

export default function NutritionData(props) {
    const classes = useStyles();

    const data = props.val.val;

    if(!data)
        return(<React.Fragment>No Data</React.Fragment>)
    
    const onDragStart = (ev, id) => {
          ev.dataTransfer.setData("id", id);
    }

    return (
        <React.Fragment>
        <CssBaseline />
            <div>
                <table className={classes.root}>
                <thead>
                </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2" >
                            Id:&nbsp;&nbsp;
                                <Tooltip title="Drag n Drop to prep area" placement="top-start">
                                <span 
                                    onDragStart = {(e) => 
                                        onDragStart(e, data.ingredient_id)}
                                    draggable
                                    //className="draggable"
                                    className={classes.id}
                                >
                                    {data.ingredient_id}
                                </span>
                                </Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td 
                                colSpan="2" 
                            >
                                <div 
                                    className={classes.header}
                                >
                                    {data.ingredient}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Brand id:</td>
                            <td className={classes.brand}>{data.brand_id}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>UOM</td>
                            <td className={classes.labels}>{data.uom}</td>
                        </tr>
                        <tr>    
                            <td className={classes.labels}>Units</td>
                            <td className={classes.labels}>{data.units}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels} >Calories</td>
                            <td className={classes.values}> {data.calories}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Protein:</td>
                            <td className={classes.values}>{data.protein}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Fat:</td>            
                            <td className={classes.values}>{data.fat}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Saturates:</td>
                            <td className={classes.values}>{data.saturates}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Carbohydrates:</td>
                            <td className={classes.values}> {data.carbohydrates}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Sugars:</td>
                            <td className={classes.values}> {data.sugars}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Fibre:</td>
                            <td className={classes.values}> {data.fibre}</td>
                        </tr>
                        <tr>
                            <td className={classes.labels}>Salt:</td>
                            <td className={classes.values}> {data.salt}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
    