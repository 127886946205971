import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as Constants from '../../config/constants';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
    list: {
        minWidth: '200px',
        background: 'white',
        color: 'grey',
        borderColor: 'grey',
        borderStyle:'solid',
        borderWidth: '2px',
        borderRadius: '4px',
        paddingLeft: '1px',
        paddingTop: '1px',
        paddingBottom: '1px',
        "&:hover":{
          borderColor: 'red',
        },
        "&:focus":{
          borderRadius: '4px',
          background: 'white',
          borderColor: 'blue'
        },
    },
    label: {
        color: 'pink',
        paddingLeft: '1px',
        position: 'left',
        fontSize: '2rem'
    },  
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      }, 
    noLabel: {
      marginTop: theme.spacing(3),
    },
    dropdownStyle: 
    {
      border: "1px solid black",
      borderRadius: "2%",
      backgroundColor:'lightgrey',
      color: 'blue',
      width: 100,
      maxHeight: 100,
      fontSize: '0.6em'
    },
    warning: {
      fontSize: 16,
      color: 'red',
      display: 'block',
      textAlign: 'bottom'
    },
    
  }));
  
  const periods = [
    '7 DAYS',
    '14 DAYS',
    '21 DAYS',
    '28 DAYS',
    '3 MONTHS',
    '6 MONTHS',
    'YTD',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    
  ];

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 12,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  function getStyles(period, periodName, theme) {
    return {
      fontWeight:
        periodName.indexOf(period) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
        fontSize: 12  
    };
  }

  
  export default function ReportingPeriodsDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [periodName, setPeriodName] = useState(['YTD', '2020', '2012', '2004']);
    const [warning, setWarning] = useState('');
    const [closed, setClosed] = useState(false);

    const HandleChange = (event) => {

        if (setPeriodName.length < 4) {
          setPeriodName(event.target.value);
        }
        else {
          console.log('You have exceeded maximum periods allowed');
          setWarning('You have exceeded maximum periods allowed');
        }
    };

    const HandleClose = () => {

        if(periodName.length > 2)  
            setClosed(true);
    };

    useEffect(() => {

      (async () => {
  
          const result = await axios.get(Constants.API + 'reportingperiods');
          console.log('Analysis.js ' + result.data.length);
          
        })();  
    }, []);

    useEffect(() => {
      console.log(periodName.length);
      props.callbackfunc(periodName);
      setClosed(false);
      }, [closed]);  

    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label"></InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={periodName}
                onClose={HandleClose}
                onChange={HandleChange}
                input={<BootstrapInput />}
              >
                {periods.map((period) => (
                  <MenuItem 
                    key={period} 
                    value={period} 
                    style={getStyles(period, periodName, theme)}
                  >
                      {period}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid 
            item xs={2} 
            style={{ display: "flex", alignItems: "center" }} 
          >
          </Grid>
          <Grid item xs={5} style={{ display: "flex", alignItems: "center" }} >
            <span className={classes.warning}>
              {warning}
            </span>
          </Grid>
        </Grid>
      </Box>
    );
  }



  