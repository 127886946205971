import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import NutritionalData from './nutritionalData';


const useStyles = makeStyles({
    root: {
      minWidth: '400px',
      display: 'flex',
      justifyContent: 'left'
    },
    card: {
        height: '330px'
    },
    title: {
      fontSize: 10,
      fontWeight: 800,
      alignContent: 'left',  
      justifyContent: 'left',
    },
    content: {
      display: 'flex',  
      width: '100%',
      alignItems: 'left', 
    },
    pos: {
      marginBottom: 5,
    },
  });

  
  export default function Ingredients(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="md">
            <span component="div" style={{ backgroundColor: 'transparent', height: '43vh' }} >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card  variant="outlined" className={classes.card}>
                    <CardContent className={classes.content}>
                      <span className={classes.content} >
                        <NutritionalData val = {props} />    
                      </span>
                    </CardContent>
                  </Card>   
                  
                </Grid>
              </Grid> 
            </span>  
          </Container>
        </React.Fragment>

    );


  }