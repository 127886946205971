import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import Trash from '../components/svg/trash';
import Clone from '../components/svg/clone';
import Edit from '../components/svg/edit';
import Cancel from '../components/svg/cancel';
import Save from '../components/svg/save';
import * as Constants from '../config/constants';
import AlertDialog from './alertDialog';
import CustomTooltip from './common/customTooltip';
import InfoPanel from './infopanel';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns/';
import toDate from 'date-fns/toDate'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import './mealbuilder.css';


class BtnCellRenderer extends React.Component {
  constructor(props) {
      super();
      console.log('PROPS ', props);
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
      
  }
  
  btnClickedHandler() {
      this.props.clicked(this.props.value);
      
  }
  
  render() {
      return (
        <Tooltip title="Kill" placement="top-start">
          <span>
            <IconButton
              onClick={this.btnClickedHandler}
              disabled={! this.props.editable }
              size='small'
              color='secondary'
            >
              <DeleteIcon />  
            </IconButton>
          </span>
        </Tooltip> 
    )
  }
}

const sessions = [
    {
      value: 'BR',
      label: 'BR',
    },
    {
      value: 'BE',
      label: 'BE',
    },
    {
      value: 'LU',
      label: 'LU',
    },
    {
      value: 'EV',
      label: 'EV',
    },
    {
      value: 'SN',
      label: 'SN',
    },
    {
      value: 'SD',
      label: 'SD',
    },
    {
      value: 'AL',
      label: 'AL',
    },
  ];

  const NuTable = styled.div`
    font-size: 12px;
    fontFamily: fontFamily:  Verdana, "Arial Black", Times, serif;
    .table {
        text-align: left;
        font-family: Verdana, Arial, Helvetica, sans-serif;
        font-weight: bold;
        margin-top: 5px;
        th {
           padding-top: 5px;
           padding-bottom: 5px;
           text-align: center;
           font-size: 10px;
           border: 1px solid #ddd;
        }
         td {
           margin: 0;
           font-size: 8px;
           padding: 1px 1px 1px 1px;
          }
           
        }
`;  

const useStyles = makeStyles(() => ({
    outlinedRoot: {
        '&:hover $notchedOutline': {
          borderColor: 'red',
          size: 'small',
          paddingTop: 0
        },
      },
      notchedOutline: {
      },
    selectinput: {
        fontSize: 10,
        fontWeight: 800,
      },
    root: {
      flexGrow: 1,
    },
    meal: {
      width: '100%',
      fontSize: 10,
      marginTop: '1px',
      fontWeight: 800,
    },
    
    datepicker: {
      width: '50%',
      fontSize: 12,
      fontWeight: '800',
    },
    linkid: {
      fontWeight: 800, 
      width: '30%',
      fontSize: 12,
      alignContent: 'center',
    },
    session: {
        fontWeight: 800,
        width: '20%',
        fontSize: 12,
    },
    buttons: {
        width: '80%',
    },
    iconbuttons: {
      padding: '0.25em',
    },
    table: {
      width: '90%'
    },
    selectTableCell: {
      width: 20,
      fontSize: 10,
      paddingRight: 1, 
      paddingLeft: 1, 
    },
    header: {
      fontSize: 10,
      paddingRight: 1, 
      paddingLeft: 1,  
    },
    container: {
      maxHeight: 200,
    },
    cells: {
      fontSize: 10,
      paddingRight: 1, 
      paddingLeft: 1, 
    },
    tableCell: {
      width: 20,
      height: 10,
      fontSize: 10,
      paddingRight: 1, 
      paddingLeft: 1, 
    },
    messagebox: {
      width: '100%',
      padding: 0,
      alignItems: 'left',
    }
  }));

export default function MealBuilder(props) {
    const classes = useStyles();
    const [mealingredientlist, setMealIngredientlist] = useState([]);
    const [ingredientlist, setIngredientlist] = useState([]);
    const [meal, setMeal] = useState('');
    const [trndate, setTrndate] = useState();
    const [newtrndate, setNewTrndate] = useState();
    const [linkid, setLinkid] = useState(props.val);
    const [session, setSession] = useState('LU');
    const [portion, setPortion] = useState(0);
    const [units, setUnits] = useState(1);

    // flags
    const [islinkiddirty, setIslinkiddirty] = useState(false);
    const [isedit, setIsedit] = useState(false);
    const [istrndirty, setIstrndirty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [canclone, setCanclone] = useState(false);
    const [canedit, setCanedit] = useState(false);
    const [cancancel, setCancancel] = useState(true);

    const [isclonee, setIsclonee] = useState();


    const [messages, setMessage] = useState('');
    const [status, setStatus] = useState();
    const [warning, setWarning] = useState(false);

    const [calories, setCalories] = useState(0);
    const [protein, setProtein] = useState(0);
    const [fat, setFat] = useState(0);
    const [saturates, setSaturates] = useState(0);
    const [carbs, setCarbs] = useState(0);
    const [sugars, setSugars] = useState(0);
    const [fibre, setFibre] = useState(0);
    const [salt, setSalt] = useState(0);

    const [rows, setRows] = useState( [] );
    const [currtrnid, setCurrtrnid] = useState('-1');

    const [custom, setCustom] = useState(
        {
          deletebtn: BtnCellRenderer,
          customTooltip: CustomTooltip  
        }
      );

    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();        

    const [opendialog, setOpenDialog] = useState(false);
    const [seling, setSelectedIngredient] = useState();

    const InputProps = {
        classes: {
          root: classes.outlinedRoot,
          notchedOutline: classes.notchedOutline
        },
      };

    const InputProps2 = {
        classes: {
            root: classes.selectinput,

        }
    };  

    const handleDate = (date) => {
       
        setTrndate(date);
        
        setIslinkiddirty(true);
    };

    const handleMealChange = (event) => {
        setMeal(event.target.value);
        setIslinkiddirty(true);
    };

    const handleSessionChange = (event) => {
      setSession(event.target.value); 
      setIslinkiddirty(true);
    };

    const handlePortionChange = (event) => {

        setPortion(event.target.value);
        setIslinkiddirty(true);
        

    }

    const editLinkid = () => {

      setIsedit(true);
      setStatus('EDIT');
      setRefresh(true);

    }

    const deleteLinkId = () => {

      axios.delete(Constants.API + 'deletelinkid/' + linkid)
        .then((response) => {
            setLinkid(0);
            //setRefresh(true);
            setMessage('Linkid ' + linkid + ' deleted');
            setWarning(false);
          },
          (error) => {
            setMessage('Error on delete - check log');
            setWarning(true);
          });
    }

    
    
    const updateLinkid = () => {
      
      axios.put(Constants.API + 'updatelinkidheader/', 
      {
        trndate: trndate,
        linkid:  linkid,
        description:  meal,
        session: session,
        portion: portion
      }
    )
    .then((response) => {

        setIsedit(false);
        setMessage('Update successful');
        setWarning(false);
        setRefresh(true);
        
      },
      (error) => {
        setMessage('Update failed');
        setWarning(true);
      });
    }


    const fetchLinkidDetails_bu = (id) => {

      setMealIngredientlist([]);

      (async () => {
        const result = await axios(Constants.API + '\z/' + id);

        var arr = [];
        arr = Object.values(result.data);
        
        setMealIngredientlist(arr[0]);
        
        setMeal(arr[0][0].link_description);
        setTrndate(arr[0][0].trn_date);
        setLinkid(arr[0][0].link_id);
        setSession(arr[0][0].meal_session);
        setPortion(arr[0][0].portion);

        setRows(arr[0]);

        setRefresh(false);

      })();
  }

    const fetchLinkidDetails = (id) => {

      setMealIngredientlist([]);

        (async () => {
          await axios.get(Constants.API + 'linkiddetails/' + id //);

          )
          .then((response) => {

            var arr = [];
            arr = Object.values(response.data);

            setMealIngredientlist(arr[0]);
            setMeal(arr[0][0].link_description);
            setTrndate(arr[0][0].trn_date);
            setLinkid(arr[0][0].link_id);
            setSession(arr[0][0].meal_session);
            setPortion(arr[0][0].portion);
            
            setRows(arr[0]); 

            setRefresh(false);
          },
          (error) => {
            
            setMessage('Failed to fetch details - see log for details');
            setWarning(true);
            
          });

          
        })();

    }

    useEffect(() => {
      console.log('useEffect 2 [] and props.val ' + props.val + ' props.isclonee ' + props.isclonee );
      setLinkid(props.val); 
      ManageFlags();
    
    }, [props.val, props.isclonee]);

    useEffect(() => {
      console.log('useEffect 3 [] and linkid ' + linkid + ' props.isclonee ' + props.isclonee);
      
      if (isclonee) {
        fetchLinkidDetails(props.val);
      }
      else {
        fetchLinkidDetails(0);
      }
      
      rollupNutritionalCost();
      
      if (refresh) {
        setIsedit(true);
        setCanedit(true);
      }
          
    }, [linkid, refresh]);

    
    const ManageFlags = () => {

      if (props.isclonee) {
        setIsclonee(true);
        setIsedit(false);
        setCanedit(false);
        setCanclone(true);
        setStatus('CLONEE');
      }
      else {
        setIsclonee(false);
        //setIsedit(true);
        setCanedit(true);
        setCanclone(false);
        isedit ? setStatus('EDIT') : setStatus('LOCKED');
      }  
    }

    const cloneLinkid = () => {

        axios.post(Constants.API + 'clonemeal/', 
          {
            trndate: trndate,
            linkid:  linkid,
            description:  meal,
            session: session
          }
        )
        .then((response) => {
          setMessage(linkid + ' cloned successfully.');
          setWarning(false);

          var arr = [];
          arr = Object.values(response.data);
          
          setMealIngredientlist(arr[0]);

          setMeal(arr[0].link_description);
          setTrndate(arr[0].trn_date);
          setLinkid(arr[0].link_id);
          setSession(arr[0].meal_session);
          setPortion(arr[0].portion);

          setCanedit(true);
          setCanclone(false);
          setIsclonee(false);
          setStatus('EDIT');
          setRows(mealingredientlist);
          
          },
          (error) => {
            setMessage('Clone failed - see log for details');
            setWarning(true);
          });
        }

    const doReset = () => {

      setIslinkiddirty(false);
      setIstrndirty(false)
      setCancancel(false);
      setCanedit(true);
      setMessage('Pending changes cancelled');
      setWarning(false);
      setRefresh(true);

      //setLinkid(100193);
      //fetchLinkidDetails(100193);

    }
    
    const rollupNutritionalCost = () => {

      setCalories(0);  
      setProtein(0.0); 
      setFat(0.0); 
      setSaturates(0.0); 
      setCarbs(0.0); 
      setSugars(0.0); 
      setFibre(0.0); 
      setSalt(0.0); 

      var ingredients = mealingredientlist;
      
      if (ingredients.length == 0)
        return;

      var cals = 0;
      var prot = 0;
      var fat = 0;
      var sats = 0;
      var carbs = 0;
      var sug = 0;
      var fib = 0;
      var salt = 0;

      var idx = 0;   
      
      for (idx = 0; idx < ingredients.length; idx++) 
      {
          cals = cals + ingredients[idx].calories;
          prot = prot + ingredients[idx].protein;
          fat = fat + ingredients[idx].fat;
          sats = sats + ingredients[idx].saturates;
          carbs = carbs + ingredients[idx].carbohydrates;
          sug = sug + ingredients[idx].sugars;
          fib = fib + ingredients[idx].fibre;
          salt = salt + ingredients[idx].salt;
      }

      setCalories(parseFloat(cals).toFixed(0));  
      setProtein(parseFloat(prot).toFixed(1)); 
      setFat(parseFloat(fat).toFixed(1)); 
      setSaturates(parseFloat(sats).toFixed(1)); 
      setCarbs(parseFloat(carbs).toFixed(1)); 
      setSugars(parseFloat(sug).toFixed(1)); 
      setFibre(parseFloat(fib).toFixed(1)); 
      setSalt(parseFloat(salt).toFixed(2)); 

  }

    
  useEffect(() => {

    axios.get(Constants.API + 'ingredients' )
      .then(response => {
        setIngredientlist(response.data);
        });
  }, []);  

  const onDragOver = (ev) => {
    ev.preventDefault();
  }
  
  const onDrop = (ev, cat) => {

    if(!isclonee) { 
      
      var id = ev.dataTransfer.getData("id");
      
      setMessage('Ingredient ' + id + ' added.');
      setWarning(false);

      setOpenDialog(true);
      setIstrndirty(true);
      setSelectedIngredient(id);
    }
    else {
      setMessage('Changes not allowed before meal cloned.');
      setWarning(true);
    }
  }

  const handlecallback = (newmeal) => {

    newmeal ? createNewMeal(seling) : addRow(seling);

    setOpenDialog(false);

  }

  const createNewMeal = (ingid) => {

    //alert("Ingredient: " + ingid);
        
    axios.post(Constants.API + 'createnewmeal/', 
        {
          _ingredientid: ingid
        }
      )
      .then((response) => {
        setMessage('New meal created');
        setWarning(false);
        setRefresh(true);
        setIsedit(true);
      },
      (error) => {
        setMessage('Failed to create new meal - see log for details');
        setWarning(true);
      });
  }  

  const addRow = (id) => {

    axios.post(Constants.API + 'addtransaction/', 
      {
        //trndate: mealingredientlist[0].trn_date,
        linkid: mealingredientlist[0].link_id,
        ingredientid:  id,
        //session: session,
        units: units,
        //portion: mealingredientlist[0].portion
      }
        )
        .then((response) => {

        setIsedit(false);
        setRefresh(true);
        setMessage('Insert successful');
        setWarning(false);
      },
      (error) => {
        setMessage('Insert failed');
        setWarning(true);
      });  

      setMessage('Ingredient ' + id + ' added.');
      setWarning(true);

    }

  const calcNutritionalCost = (trnid) => {

      var list = mealingredientlist;

      var ingid = 0;
      var i = list.length;
      while ( i --> 0 ) {
          if ( list[i].trn_id == trnid ) {
            ingid = list[i].ingredient_id;
            break;
          }
      }    

      var ingredient = getIngredientData(ingid);
        
      var i = list.length;
      while ( i --> 0 ) {
        if ( list[i].trn_id == trnid ) {
            list[i].calories = ingredient.calories * list[i].portion * list[i].units;
            list[i].protein = ingredient.protein * list[i].portion * list[i].units;
            list[i].fat = ingredient.fat * list[i].portion * list[i].units;
            list[i].saturates = ingredient.saturates * list[i].portion * list[i].units;
            list[i].carbohydrates = ingredient.carbohydrates * list[i].portion * list[i].units;
            list[i].sugars = ingredient.sugars * list[i].portion * list[i].units;
            list[i].fibre = ingredient.fibre * list[i].portion * list[i].units;
            list[i].salt = ingredient.salt * list[i].portion * list[i].units;
            break;
          }
        }    
        
      setMealIngredientlist(list);

    }

  const getIngredientData = (ingredientid) => {
      var ing = '';
      var i = ingredientlist.length;
      while ( i --> 0 ) {
        if ( ingredientlist[i].ingredient_id == ingredientid ) {
          ing = ingredientlist[i];
          break;
        }
      }   
      
      return ing;

    }

  const UpdateUnits = (event) => {

    if(event.node===undefined) {
      return;
    }

    var trnid = event.node.data.trn_id;
    var newunits = event.newValue;

    axios.put(Constants.API + 'updatetrnidqty/', 
        {
          trnid:  trnid,
          qty: newunits,
        }
      )
      .then((response) => {

      console.log(response);
      setRefresh(true);
      calcNutritionalCost(trnid);
      setMessage('Transaction ' + trnid + ' quantity changed to ' + newunits);
      setWarning(false);
      },
        (error) => {
        console.log(error);
      });
  }

  const UpdatePortion = (event) => {

    if(event.node===undefined) {
      return;
    }
      var trnid = event.node.data.trn_id;
      var newportion = event.newValue;
      
      axios.put(Constants.API + 'updatelinkidportion/', 
        {
          linkid:  linkid,
          portion: newportion,
        }
      )
      .then((response) => {
        setRefresh(true);
        setMessage('Link ' + linkid + ' portion changed to ' + newportion);
        setWarning(false);
      },
        (error) => {
        
      });
  }

  const DeleteTrnid = (field) => {
    
    axios.post(Constants.API + 'deletetrn/', 
        {
          trnid: field,
          linkid: null,
        }
      )
        .then((response) => {

        setMessage('Transaction ' + field + ' deleted.');
        setWarning(false);
        
        setRefresh(true);
        
        },
          (error) => {
            setMessage('Failed to delete transaction ' + field);
            setWarning(true);
      });
  }

  const IngredientsTable2 = () => {

    const columnDefs = [
      {
        maxWidth: 45,
        headerName: '',
        cellRendererParams:  {
          clicked: function(field)  {
            DeleteTrnid(field);
          },
          //...props
          //editable: props.isclonee
          editable: isedit
        },
        cellRenderer: 'deletebtn',
        field: 'trn_id'
      },
      { 
        headerName: 'TId',
        field: 'trn_id', 
        maxWidth: 75, 
      },
      {
        headerName: 'IId',
        field: 'ingredient_id',
        maxWidth: 60,
      },
      {   
        headerName: 'Ingredient',
        field: 'ingredient',
        maxWidth: 200,
        tooltipField: 'ingredient',
        tooltipComponent: 'customTooltip',
        tooltipComponentParams: { color: '#ececec' },
      },
      { 
        headerName: 'Qty',
        field: 'units',
        editable: isedit,
        onCellValueChanged: UpdateUnits,
        maxWidth: 60,
        cellStyle: { fontSize: '14px', cursor: 'pointer', 'font-weight': 'bold'}
      },
      
      
    ]

    const onGridReady = (params) => {
      setGridApi(params.api);
      setColumnApi(params.columnApi);

    }

    return (
      <React.Fragment>
      <CssBaseline />
      <div
          className="droppable"
          onDragOver={(e)=>onDragOver(e)}
          onDrop={
            (e)=>onDrop(e, "complete")
            }
      >
        <AlertDialog open={opendialog} callbackfunc={(x) => 
                  handlecallback(x)} />
        <div className="ag-theme" style={ { paddingTop: 10, height: "200px", width: "100%" } }>
          <div
            id="ingredientsGrid"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowStyle= {{ 
                fontSize: '14px', 
                paddingTop: '0', 
                paddingBottom: '0',
                paddingLeft: '0' }}
              height='100px'
              rowData={rows}
              marginBottom='20px'
              tooltipShowDelay= {0}
              frameworkComponents={custom}
              onGridReady={onGridReady}
            />
          </div>
        </div>
        </div>
          
        </React.Fragment>
      )
  }

  const nutritionalPanel = () => {

      return(
        <React.Fragment>
        <CssBaseline />
            <NuTable>
            <table className='table'>
                    <thead>
                        <tr>
                            <th colSpan="2">Nutritional Cost</th>
                        </tr>
                    </thead>  
                    <tbody>      
                            <tr>
                            <td>Calories</td>
                            <td style={{textAlign: "right"}}> {calories}</td>
                            </tr>
                            <tr>
                            <td>Protein:</td>
                            <td style={{textAlign: "right"}}>{protein}</td>
                            </tr>
                            <tr>
                            <td>Fat:</td>            
                            <td style={{textAlign: "right"}}>{fat}</td>
                            </tr>
                            <tr>
                            <td>Saturates:</td>
                            <td style={{textAlign: "right"}}>{saturates}</td>
                            </tr>
                            <tr>
                            <td>Carbohydrates:</td>
                            <td style={{textAlign: "right"}}> {carbs}</td>
                            </tr>
                            <tr>
                            <td>Sugars:</td>
                            <td style={{textAlign: "right"}}> {sugars}</td>
                            </tr>
                            <tr>
                            <td>Fibre:</td>
                            <td style={{textAlign: "right"}}> {fibre}</td>
                            </tr>
                            <tr>
                            <td>Salt:</td>
                            <td style={{textAlign: "right"}}> {salt}</td>
                            </tr>
                        </tbody>
                        </table>



                        </NuTable>
        </React.Fragment>
    )

  }

    return (

    <React.Fragment>
    <CssBaseline />
    <Grid container spacing={1}>
      
        <Grid item md={12}>
          <TextField 
            id="selectedmeal" 
            className={classes.meal} 
            disabled={!isedit} 
            value={meal} 
            onChange={handleMealChange}  
            variant="outlined"
            margin='dense'
            label = "Meal"
            InputProps={InputProps}
        />
        </Grid>
        <Grid item md={12}>
        <div id='linkiddiv'>
        <span id="linkid">
          <TextField 
            id="linkid"
            //label = "Link id"
            value={linkid} 
            disabled={true}
          />
          </span>
        
        <MuiPickersUtilsProvider utils={DateFnsUtils}>  
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="dense"
            id="datepicker"
            autoOk={true}
            disabled={true}
            disableFuture={true}
            value={trndate}
            //label='Trn date'
            onChange={handleDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              }}
          />
        </MuiPickersUtilsProvider>
        
        <span id="portion">
          <TextField 
            id="portion"
            required
            type="number"
            margin="dense"
            value={portion} 
            disabled={!isedit}
            onChange={handlePortionChange}
            variant="outlined"
            label='Portion'
            inputProps={{
              step: 0.25,
            }}
          />
        </span>
        <span id="session">
        <TextField
          id="session"
          disabled={!isedit} 
          select
          margin="dense"
          value={session}
          onChange={handleSessionChange}
          InputProps={InputProps2}
          variant="outlined"
          label='Session'
        >
          {sessions.map((option) => (
            <MenuItem className={classes.session} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </span>
        </div>
        </Grid>
        <Grid item md={12}>
          <div id="buttondiv">
          <Tooltip title="Delete" placement="top-start">
            <span><IconButton
              className={classes.iconbuttons}
              disabled={!isedit} 
              onClick={deleteLinkId}>
                <Trash />  
            </IconButton></span>
          </Tooltip>  
          <Tooltip title="Clone" placement="top-start">
            <span><IconButton
              className={classes.iconbuttons}
              disabled={!canclone} 
              onClick={cloneLinkid}>
                <Clone fill='green' />  
            </IconButton></span>
          </Tooltip>
          <Tooltip title="Edit" placement="top-start">
            <span><IconButton
              className={classes.iconbuttons}
              disabled={!canedit}
              onClick={editLinkid}>
                <Edit fill='green' />  
            </IconButton></span>
          </Tooltip>
          <Tooltip title="save" placement="top-start">
            <span>
              <IconButton
              className={classes.iconbuttons}
              disabled={!islinkiddirty} 
              onClick={updateLinkid}>
                <Save fill='green' />  
            </IconButton>
            </span>
          </Tooltip> 
          </div> 
        </Grid>
        <Grid item md={9}>
            {IngredientsTable2()}
        </Grid>
        <Grid item md={3}>
          <Grid item md={12}>
            {nutritionalPanel()}
          </Grid> 
        </Grid>
        <Grid item md={12}>
          <InfoPanel msg={messages} warning={warning} status={status} /> 
        </Grid>
      </Grid>
           {/* isedit:{isedit ? 'true' : 'false'}<br/>
            props.isclonee:{props.isclonee ? 'true' : 'false'}<br/>
            isclonee:{isclonee ? 'true' : 'false'}<br/>
            canedit:{canedit ? 'true' : 'false'}<br/>
            refresh:{refresh ? 'true' : 'false'}
          */}

    </React.Fragment>  
    )
  }
