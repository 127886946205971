import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IntakeTables from "./intakeTables";



export default function Intake() {
  

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '70vh' }} >
         <IntakeTables />
        </Typography>  
      </Container>
    </React.Fragment>
  );
}