import React, { useState, useEffect, PureComponent } from 'react';
import axios from 'axios';
import {
  BarChart, LabelList, Label, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import * as Constants from '../config/constants';

  export default function AlcoIntake(props) {  

    const [data, setData] = useState([]);
    
    useEffect(() => {

      (async () => {

        //console.log('AlcoIntake');
        //console.log(props.selperiods[0]);
        //console.log(props.selperiods[1]);
        //console.log(props.selperiods[2]);
        //console.log(props.selperiods[3]);

          const result = await axios.post(Constants.API + 'DYNSTATS_Alco/',
          {
            param1: props.selperiods[0],
            param2: props.selperiods[1],
            param3: props.selperiods[2],
            param4: props.selperiods[3],

          });
          
          setData(result.data);
            
          
        })();  
    
  
    }, [props]);

    
    return (
        <div>
         
        
        <BarChart
          width={300}
          height={200}
          data={data}
          margin={{
            top: 15, right: 10, left: 20, bottom: 15,
          }}
          
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Period"  fontSize= {10} stroke='red' >
            <Label fontSize={16} stroke='green' value="Alcohol" offset={0} position="insideBottom" />
          </XAxis>  
          <YAxis 
            yAxisId="left" 
            orientation="left" 
            stroke="#8884d8"
            fontSize= {10} 
            label={{ fontSize: 10, stroke: 'orange', margin: 0, value: 'Daily Units', angle: -90, position: 'insideLeft' }}  
          />
          <Bar 
            yAxisId="left" 
            dataKey="Daily Alco Units" 
            fill="#d2d2e0" 
            label={{ fontSize: 12}}
          >
             
          </Bar>  
          
        </BarChart>
        </div>
      );
    //}-
  }
  