import React, { useState, useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import CalsWeight from './calsWeight';
import FatsCarbs from './fatsCarbs';
import AlcoIntake from './alcoIntake';
import Bespoke from './bespoke';

import ReportingPeriodsDialog from './dialogs/ReportingPeriodsDialog';


export default function Analysis() {

  const [periods, setPeriods] = useState(['7 DAYS', '14 DAYS', 'YTD']);

  const CallBackFunc = (periods) => {
    setPeriods(periods);
    
  }

  

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '70vh' }} >
          <Box>
          <span 
            style={
              {
                fontSize: 16, 
                fontWeight: 700, 
                padding: 0
              }
            }
          >
            <h4>CHARTS:</h4>
          </span>
           { <ReportingPeriodsDialog callbackfunc={(x) => 
                  CallBackFunc(x)} /> }
          </Box>  
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <FatsCarbs selperiods={periods} /> 
          </Grid>
          <Grid item xs={4}>
            <CalsWeight selperiods={periods} />
          </Grid>
          <Grid item xs={4}>
            <AlcoIntake selperiods={periods} />
          </Grid>
          <Grid item xs={12}>
          <span 
            style={
              {
                fontSize: 16, 
                fontWeight: 700, 
                padding: 0
              }
            }
          >
            <h4>Select a category for each chart</h4>
          </span>
          </Grid>
          <Grid item xs={4}>
            <Bespoke selperiods={periods} />
          </Grid>
          <Grid item xs={4}>
            <Bespoke selperiods={periods} />
          </Grid>
          <Grid item xs={4}>
            <Bespoke selperiods={periods} />
          </Grid>
        </Grid>  
        </Typography>  
      </Container>
    </React.Fragment>
  );
}