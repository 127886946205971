import React, { useState, useEffect, PureComponent } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, Label, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import * as Constants from '../config/constants';



  //export default class CalsWeight extends PureComponent {
  export default function CalsWeight(props) {  

    const [data, setData] = useState([]);
    
    useEffect(() => {

      (async () => {

        console.log('CalsWeight');
        console.log(props.selperiods[0]);
        console.log(props.selperiods[1]);
        console.log(props.selperiods[2]);
        console.log(props.selperiods[3]);

        const result = await axios.post(Constants.API + 'DYNSTATS_CalsWeight/',
          {
            param1: props.selperiods[0],
            param2: props.selperiods[1],
            param3: props.selperiods[2],
            param4: props.selperiods[3],

          });
          
        setData(result.data);
          
        })();  

    }, [props]);

    return (
        <BarChart
          width={300}
          height={200}
          data={data}
          margin={{
            top: 15, right: 30, left: 10, bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Period"  fontSize= {10} stroke='red' >
          <Label fontSize={16} stroke='green' value="Calories and Weight" offset={0} position="insideBottom" />
          </XAxis>
          <YAxis 
            yAxisId="left" 
            orientation="left" 
            stroke="#8884d8" 
            fontSize= {10} 
            label={{ fontSize: 10, stroke: 'orange', margin: 0, value: 'Calories', angle: -90, position: 'insideLeft' }}
          />
            
          <YAxis 
            yAxisId="right" 
            orientation="right" 
            stroke="#82ca9d" 
            fontSize= {10} 
            label={{ fontSize: 10, stroke: 'orange', margin: 0, value: 'Weight(lbs)', angle: -90, position: 'insideRight' }}
          />
          <Tooltip />
          
          <Bar 
            yAxisId="left" 
            dataKey="Calories" 
            fill="#8884d8" 
              
          />
          <Bar yAxisId="right" dataKey="Weight" fill="#82ca9d" />
        </BarChart>
      );
    //}-
  }
  