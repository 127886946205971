import React, { Component } from 'react';

export default class TimeRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.valueTime(),
    };
  }

  valueTime() {
    return this.props.value;
    //return 'test';
  }

  render() {
    return <span>{this.state.value}</span>;
  }
}