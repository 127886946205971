import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";

import InfoPanel from './infopanel';
import * as Constants from  '../config/constants';


class BtnCellRenderer extends React.Component {
  constructor(props) {
      super();
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
      this.props.clicked(this.props.value);
      
  }
  render() {
      return (
          <Tooltip title="Delete" placement="top-start">
              <span>
                  <IconButton
                      onClick={this.btnClickedHandler}
                      disabled={this.props.data.isinuse }
                      size='small'
                      color='secondary'
                  >
                      <DeleteIcon />  
                  </IconButton>
              </span>
          </Tooltip> 
    )
  }
}


export default function MaintainBrands() {

    const [refresh, setRefresh] = useState(false);
    const [brands, setBrands] = useState([]);
    const [allowedit, setAllowedit] = useState(true);
    const [frameworkcomponents, setDeleteButton] 
            = useState({deletebtn: BtnCellRenderer});
    const [isdirty, setIsdirty] = useState(false);   
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState(false);
    const [status, setStatus] = useState();
    
    useEffect(() => {

      var unmounted = false;
      
      if (!unmounted)
        LoadBrands();

      return () => {
        unmounted = true;
      }  
      
    
    }, [refresh]);

    
    const LoadBrands = () => {

      (async () => {
        const result = await axios(Constants.API + 'brands');
        //setBrands(result.data);

        var brands = Object.values(result.data);

          var arr = [];
          arr = brands;

          setBrands(arr[0]);


        setRefresh(false);
        
      })();      
    }

    const AddBrand = (event)=> {

      var newbrand = [];

      newbrand.brand_id = -1;
      setBrands([newbrand, ...brands]);

      setIsdirty(true);

    }

    const UpdateBrand = (event) => {

      var id = event.node.data.brand_id;
      var brand = event.node.data.brand;
      var notes = event.node.data.notes;

      if(id!==-1) {
        (async () => {
          await axios.post(Constants.API + 'amendbrand/', 
          {
              id:  id,
              brand:  brand,
              notes:  notes,
              
          } ).then((result) => {
            setRefresh(true);
            setMessage('brand ' + id + ' amended ');
            },
              (error) => {
              console.log(error);
              setMessage('Failed to amend brand ' 
                + id + '[' + error + ']');
            });
  
         
        })();    
      }
      else {

        axios.post(Constants.API + 'addbrand/', 
        {
            brand:  brand,
            notes:  notes,
            
        } ).then((result) => {
          setRefresh(true);
          setMessage('Brand added' );
          },
            (error) => {
            console.log(error);
            setMessage('Failed to add brand ' 
              + id + '[' + error + ']');
          });;

        setRefresh(true);
        setIsdirty(false);
      }
    }  

    const DeleteBrand = (field) => {

      axios.delete(Constants.API + 'deletebrand/' + field)
        .then((response) => {
            var rowsdeleted = 0;
            rowsdeleted = response.data.affectedRows;
            if (rowsdeleted > 0) {
              setMessage('Brand id ' + field + ' deleted');
              setRefresh(true);
              console.log('Rows affected: '+ response.data.affectedRows);
            }
            else {
              setMessage('No records to delete');
            }
          },
          (error) => {
            setMessage('Error on delete ' + '[' + error +']')
          });
          
    } 

    return(
    
        <div>
            <div>
            <h5>    
            <ul> 
                <li>Add a new brand name</li>
                <li>Edit an existing brand name</li>
                <li>Delete an unlinked brand name</li>
            </ul>
            </h5>
            </div>
            <Button 
                    variant="contained" 
                    size="small"
                    color="primary"
                    disabled={isdirty}
                    onClick={AddBrand}
                >
                Add Brand  
            </Button>
        <div className="ag-theme" style={ { paddingTop: 50, height: 300, width: "100%" } }>
            <AgGridReact 
              onCellValueChanged={UpdateBrand}
              rowStyle= {{ fontSize: '10px',paddingLeft: '0' }}
              cell-horizontal-padding={0}
              rowSelection="single"
              rowData={brands}
              frameworkComponents={frameworkcomponents}
            >
              <AgGridColumn
                width={70}
                headerName=""
                cellRendererParams = {
                    {clicked:function(field)  {
                      DeleteBrand(field);
                    },
                  }
                }
                cellRenderer = 'deletebtn'
                field="brand_id"
              />
              <AgGridColumn
                headerName="Id"
                width={70}
                field="brand_id"
                sortable={true}
              /> 
              <AgGridColumn
                headerName="Brand name"
                width={150}
                field="brand"
                sortable={true}
                editable={allowedit}
              /> 
              <AgGridColumn
                headerName="Notes"
                width={150}
                field="notes"
                editable={allowedit}
              />    
            </AgGridReact>
        </div> 
        <InfoPanel msg={message} warning={warning} status={status}/>
        </div>     
    )
}