import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns/';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


import * as Constants from  '../config/constants';
import InfoPanel from './infopanel';

const ContainerDiv = styled.div`
  width: 80%;
  border: 5px solid rgb(111,41,97);
  border-radius: .5em;
  padding: 10px;
  

`;

const ItemDiv = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: start;
`;


const ParamsDiv = styled.div`
  width: 100%;
  margin: auto;
`;

const ResultsDiv = styled.div`
    margin-top: 25px;
    margin-left: 10px
`;

const MsgDiv = styled.div`
  width: 100%
`;

const useStyles = makeStyles((theme) => ({
    btn: {
        size: 'small',
        paddingTop: 0,
      },
    divs: {

    }, 
    form: {
      "& > *": {
          margin: theme.spacing(1),
          width: "16ch"
        },
      marginTop: 10  

  }, 
    }));      


export default function IngredientUsage() {

  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [warning, setWarning] = useState(false);
  const [status, setStatus] = useState('');

  const [ingredientid, setIngredientid] = useState();
  const [trndate, setTrndate] = useState();

  const [ingredient, setIngredient] = useState();
  const [uom, setUom] = useState();
  const [units, setUnits] = useState();
  const [consumed, setConsumed] = useState();

  const [isvisible, setIsvisible] = useState(false);

  const [isdirty, setIsdirty] = useState(false);

  const HandleChange = (event) => {

    setIngredientid(event.target.value);
    setMessage(event.target.value);
    setIsdirty(true);
    setIsvisible(false);

  }   
  
  const HandleDateChange = (date) => {
    setTrndate(date);
    
  };
    
  const FetchData = () => {

    if (trndate === undefined) {
      setMessage('No date selected');
      setWarning(true);
      return;
    }
    
    axios.post(Constants.API + 'fetch_ingredient_usage_bydate/', 
      {
        _id:  ingredientid,
        _date:format(trndate, 'yyyy-MM-dd'),
      }
    )
    .then((response) => {


      try {
        setIngredient(response.data[0].ingredient);
        setUom(response.data[0].uom);
        setUnits(response.data[0].units);
        setConsumed(response.data[0].consumed);
        setMessage('Usage since ' + format(trndate, 'yyyy-MM-dd') + ' retrieved for ' + ingredientid + '.');
        setWarning(false);
      } catch(e) {
          setMessage('No consumption for given date range');
          setConsumed(0);
      } 

      
      setIsvisible(true);
    },
    (error) => {

      setMessage('Failed to retrieve ingredient usage.');
      setWarning(true);
      
    });
           
  }

  const ResultPanel = () => {

    return (

      <ContainerDiv>
        <Grid container spacing={0}>
          <Grid item md={6}>
            Ingredient id: 
          </Grid>
          <Grid item md={6}>
            {ingredientid}
          </Grid>
          <Grid item md={6}>
            Ingredient: 
          </Grid>
          <Grid item md={6}>
            {ingredient}
          </Grid>
          <Grid item md={6}>
            UOM: 
          </Grid>
          <Grid item md={6}>
            {uom}
          </Grid>
          <Grid item xs={6}>
            Units: 
          </Grid>
          <Grid item xs={6}>
            {units}
          </Grid>
          <Grid item xs={6}>
            Consumed: 
          </Grid>
          <Grid item xs={6}>
            {consumed}
          </Grid>
          <Grid item xs={6}>
            Since: 
          </Grid>
          <Grid item xs={6}>
            {format(trndate, 'do MMM yyyy')}
          </Grid>
        </Grid>
      </ContainerDiv> 
    )
  }  

  

    return(
    
      
      <React.Fragment>  
      <h5>    
          <ul> 
              <li>Fetch consumption data on a single ingredient.</li>
              <li>Parameters:</li>
              <ul>
                  <li>Ingredient id.</li>
                  <li>Start date.</li>
              </ul>
          </ul>
      </h5> 
      <ParamsDiv>
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          id="ingid"
          onChange={HandleChange}
          label="Id"
          size="small"
          defaultValue="0000"
          variant="outlined"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>  
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="dense"
            id="datepicker"
            autoOk={true}
            disableFuture={true}
            value={trndate}
            //onChange={setTrndate}
            onChange={HandleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              }}
          />
        </MuiPickersUtilsProvider>  
        <Button 
          variant="contained" 
          color="primary"
          size="small"
          disabled={!isdirty}
          onClick={FetchData}
        >
          Fetch
        </Button>
      </form>
      </ParamsDiv>  
        {isvisible &&<div>{ResultPanel()}</div>}
        <MsgDiv>
          <InfoPanel msg={message} warning={warning} status={status} /> 
        </MsgDiv>
        
      </React.Fragment>  
      
    
    
    )

}