import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../auth/react-auth0-spa';
import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/ToolTip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '../components/svg/home';
import Intake from '../components/svg/intake';
import Charts from '../components/svg/charts';
import Kitchen from '../components/svg/kitchen';
import Exit from '../components/svg/exit';
import Login from '../components/svg/login';
import Maintenance from '../components/svg/maintenance';

const useStyles = makeStyles({
  root: {
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: 'transparent'
  },
});

const Navigation = () => {
  
  const { isAuthenticated, loginWithRedirect, logoutWithRedirect, loading } = useAuth0();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  
  return (
    <div>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {isAuthenticated && 
          <BottomNavigationAction component={Link} to='/' icon={<Home fill='green'/>} />
        }
        {isAuthenticated && 
          <ToolTip title="Consumption" placement="top-right">
            <BottomNavigationAction component={Link} to='/intake' icon={<Intake fill='green' />} />
          </ToolTip>
        }
        {isAuthenticated && 
          <ToolTip title="Charts" placement="top-right">
            <BottomNavigationAction component={Link} to='/analysis' icon={<Charts fill='green' />} />
          </ToolTip>
        }
        {isAuthenticated && 
          <ToolTip title="Kitchen" placement="top-right">
            <BottomNavigationAction component={Link} to='/kitchen' icon={<Kitchen fill='green' />} />
          </ToolTip>  
        }
        {isAuthenticated && 
          <ToolTip title="Maintenance" placement="top-right">
            <BottomNavigationAction component={Link} to='/maintenance'icon={<Maintenance fill='green' />} />
          </ToolTip>
        }
        {isAuthenticated && 
        <BottomNavigationAction 
          onClick={() => logoutWithRedirect({})} 
          icon={<Exit fill='red'/>} 
        />}
        {!isAuthenticated && 
        <BottomNavigationAction 
          onClick={() => loginWithRedirect({})}
          icon={<Login fill='green'/>}
        />
        }  
        </BottomNavigation>
    </div>
  );
}

export default Navigation;