import React, { Component } from 'react';
import './tooltip.css';


export default class CustomTooltip2 extends Component {
    
    getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
    return (
      <div
        className="custom-tooltip"
        //style={{ backgroundColor: this.props.color || 'white' }}
      >
        <p>
          <span>{data.description}</span>
        </p>
        
      </div>
    );
  }
}