import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import * as Constants from  '../config/constants';
import InfoPanel from './infopanel';
import NumberFormatCustom from './common/numberFormatCustom';

const useStyles = makeStyles((theme) => ({
    id: {
        fontWeight: '800',
    },
    fields: {
        '&$disabled': {
            fontWeight: '500'
        },
        fontWeight: '800',
    },
    btndiv: {
        '& > *': {
          margin: theme.spacing(2),
        },
    },
    
}));
  
const uoms = [
    {
        'value': 'ea',
        'label': 'each',
    },
    {
        'value': 'gr',
        'label': 'grams',
    },
    {
        'value': 'lt',
        'label': 'litre',
    },
    {
        'value': 'ml',
        'label': 'mililitre',
    },
    {
        'value': 'un',
        'label': 'units',
    },
    {
        'value': 'tbsp',
        'label': 'table spoon',
    },
    {
        'value': 'tsp',
        'label': 'tea spoon',
    }, 
     
  ];
  
  

export default function MaintainIngredients() {

    const classes = useStyles();

    const [values, setValues] = useState({
        ingredient: '*new ingredient*',
        units: 0,
        calories: 0,
        protein: 0,
        fat: 0,
        saturates: 0,
        carbohydrates: 0,
        sugars: 0,
        fibre: 0,
        salt: 0,
        isinuse: true,
      });
    

    const [id, setId] = useState(1);
    const [brand, setBrand] = useState(' ');
    const [uom, setUom] = useState(' ');
    const [category, setCategory] = useState(' ');
    const [ingredients, setIngredients] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isedit, setIsedit] = useState(false);
    const [isnew, setIsnew] = useState(false);
    const [isdirty, setIsdirty] = useState(false);
    const [isidlocked, setIsidlocked] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [message, setMessage] = useState();
    const [warning, setWarning] = useState(false);
    const [status, setStatus] = useState('');
;
    useEffect(() => {
     
        (async () => {
          const result = await axios(Constants.API + 'categories/');
          var arr = [];
          arr = Object.values(result.data);
          setCategories(arr[0]);
    
        })();
      }, []);

    useEffect(() => {

        
     
        (async () => {


          const result = await axios(Constants.API + 'brands/');

          //var brands = Object.values(result.data);

         

          var arr = [];
          arr = Object.values(result.data);

           //alert(arr[0]);

          setBrands(arr[0]);
          
        })();

      }, []);  

    useEffect(() => {
     
        (async () => {
          const result = await axios(Constants.API + 'ingredients/');
          var arr = [];
          arr = Object.values(result.data);
          setIngredients(arr[0]);
          setRefresh(false);
        })();
      }, [refresh]); 
      
    
    useEffect(() => {
        
        var length = ingredients.length;
        var lastid = 0;

        if(length > 0){
            lastid = ingredients[length-1].ingredient_id;
            setId(lastid);
        }
        
    }, [ingredients]);  

    
    useEffect(() => {
        
        GetIngredient(id);
        
    }, [ingredients, id]);   


    const AddOrAmend = () => {

        (async () => {
            const result = await axios.post(Constants.API + 'addamendingredient/', 
            {
                id:  id,
                ingredient:  values.ingredient,
                brand:  brand,
                uom:  uom,
                units:  values.units,
                calories:  values.calories,
                protein:  values.protein,
                fat:  values.fat,
                saturates: values.saturates,
                carbohydrates: values.carbohydrates,
                sugars: values.sugars,
                fibre: values.fibre,
                salt: values.salt,
                category: category 
            } )
            .then((result) => {
                setRefresh(true);
                setMessage('Ingredient ' + id + ' amended ');
                },
                  (error) => {
                  console.log(error);
                });
        })();    
    }  

    
    const HandleBrandChange = (event) => {
        setBrand(event.target.value);
        setIsdirty(true);
      }  

    const HandleUOMChange = (event) => {
        setUom(event.target.value);
      }

    const HandleCategoryChange = (event) => {
        setCategory(event.target.value);
      }  

    const HandleIngredientSearch = (event) => {
        var ingredientid = event.target.value;

        setId(ingredientid);

        GetIngredient(ingredientid);

    }    

    const GetIngredient = (id) => {

        for(var i = 0; i < ingredients.length; i++)
        {
            if(ingredients[i].ingredient_id == id)
            {
                setBrand(ingredients[i].brand_id)
                setUom(ingredients[i].uom)
                setCategory(ingredients[i].category_id);
                setValues(
                    {
                    ingredient: ingredients[i].ingredient 
                    ,units: ingredients[i].units    
                    ,calories: ingredients[i].calories
                    ,protein: ingredients[i].protein
                    ,fat: ingredients[i].fat 
                    ,saturates: ingredients[i].saturates
                    ,carbohydrates: ingredients[i].carbohydrates
                    ,sugars: ingredients[i].sugars
                    ,fibre: ingredients[i].fibre
                    ,salt: ingredients[i].salt
                    ,isinuse: ingredients[i].isinuse
                    });
               
            }
        }
    }  

    
    const handleChange = (event) => {
       
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
      };


    const HandleCloneClick = () => {
        //alert("id: " + id);
        (async () => {
            const result = await axios.post(Constants.API + 'cloneingredient/', 
            {
                ingredientid:  id,
            } )
            .then((result) => {
                setRefresh(true);
                setMessage('Ingredient ' + id + ' cloned ');
            },
                (error) => {
                    console.log(error);
                    setMessage('Clone failed: ' + error);
                });
        })();     
    }  
    
    const HandleEditClick = () => {
        setIsedit(true);
        setIsdirty(true);
        setStatus('EDIT MODE');
    }

    const HandleNewClick = () => {

        setIsedit(true);
        setIsnew(true);
        setStatus('EDIT');
        
        setId(-1);
        setBrand(ingredients[0].brand_id);
        setUom(' ');
        setCategory(ingredients[0].category_id);
        setValues(
                {
                    ingredient: '*ingredient name* '
                    ,units: 0
                    ,calories: 0
                    ,protein: 0
                    ,fat: 0
                    ,saturates: 0
                    ,carbohydrates: 0
                    ,sugars: 0
                    ,fibre: 0
                    ,salt: 0
                    ,isinuse: true
                });
        //setIsnew(false);
        setIsdirty(true);

    }

    const HandleDeleteClick = () => {

        axios.delete(Constants.API + 'deleteingredient/'+ id)

        .then ((response) => {
            // Show success
            setMessage(response.data);
          })
          .catch((response) => {
             //Show error
            setMessage('Error on delete');
          });


        
            setRefresh(true);
            setIsedit(false);
    }

    const HandleSaveClick = () => {

        setStatus('');
        setIsedit(false);
        setIsnew(false);
        setRefresh(true);
        AddOrAmend();
        setIsdirty(false);

    }

return(

    <React.Fragment>
        <CssBaseline />
        <Grid 
            container
            spacing={0}>
            <Grid item md={9}>
            <h5>    
            <ul> 
                <li>Create a new ingredient.</li>
                <li>Clone an ingredient.</li>
                <li>Search for and edit an existing ingredient.</li>
                <li>Delete an unlinked ingredient.</li>
            </ul>
            </h5>  
            </Grid>
            <Grid item md={2}>
            <div style={{visibility: !isnew ? 'visible' : 'hidden'}}>
            <TextField
                id="id"
                width='50%'
                autoFocus={true}
                label="Id:"
                disabled={isdirty}
                value={id}
                onChange={HandleIngredientSearch}
                variant="outlined"
                InputProps={{
                    classes: {
                        root: classes.id
                    },
                    inputComponent: NumberFormatCustom,
                }}
            />  
            </div>
            </Grid>
            
            <Grid item md={9}>
                <TextField
                    select
                    id="brand"
                    label="Brand:"
                    value={brand}
                    disabled={!isedit}
                    margin='dense'
                    fullWidth={true}
                    onChange={HandleBrandChange}
                    variant="outlined"
                    SelectProps={{
                        native: true,
                        shrink: brand ? true: false,
                        
                        
                      }}
                      InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}  
                >  
                    {brands.map((option) => (
                    <option 
                        key={option.brand_id} 
                        value={option.brand_id}>
                            {option.brand}
                    </option>
                ))}  
                </TextField>
            </Grid>
            <Grid item md={12}>
                <TextField
                    id="ingredient"
                    label="Ingredient name:"
                    disabled={!isedit}
                    margin='dense'
                    fullWidth={true}
                    onChange={handleChange}
                    value={values.ingredient}
                    variant="outlined"
                    name= "ingredient"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={4}>
                <TextField
                    select
                    id="uom"
                    label="UOM:"
                    value={uom}
                    disabled={!isedit}
                    margin='dense'
                    fullWidth={true}
                    onChange={HandleUOMChange}
                    variant="outlined"
                    SelectProps={{
                        native: true,
                    }}
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
            
                >
                {uoms.map((option) => (
                    <option 
                        key={option.value} 
                        value={option.value}>
                            {option.label}
                    </option>
                ))}  
                </TextField>    
            </Grid>
            <Grid item md={2}>
                <TextField
                    id="units"
                    label="Units:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.units}
                    variant="outlined"
                    name="units"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={6}>
                <TextField
                    select
                    id="category"
                    label="Category:"
                    disabled={!isedit}
                    margin='dense'
                    fullWidth={true}
                    value={category}
                    onChange={HandleCategoryChange}
                    variant="outlined"
                    SelectProps={{
                        native: true,
                        shrink: category ? true: false
                    }}
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                >
                {categories.map((option) => (
                    <option 
                        key={option.category_id} 
                        value={option.category_id}>
                            {option.category}
                    </option>
                ))}
                </TextField>    
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="calories"
                    label="Calories:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.calories}
                    variant="outlined"
                    name="calories"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                /> 
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="protein"
                    label="Protein:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.protein}
                    variant="outlined"
                    name="protein"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="fat"
                    label="Fat:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.fat}
                    variant="outlined"
                    name="fat"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="saturates"
                    label="Saturates:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.saturates}
                    variant="outlined"
                    name="saturates"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="carbohydrates"
                    label="Carbohydrates:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.carbohydrates}
                    variant="outlined"
                    name="carbohydrates"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                /> 
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="sugars"
                    label="Sugars:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.sugars}
                    variant="outlined"
                    name="sugars"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="fibre"
                    label="Fibre:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.fibre}
                    variant="outlined"
                    name="fibre"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={3}>
                <TextField
                    id="salt"
                    label="Salt:"
                    disabled={!isedit}
                    margin='dense'
                    onChange={handleChange}
                    value={values.salt}
                    variant="outlined"
                    name="salt"
                    InputProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                        inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.fields,
                            disabled: classes.disabled,
                        },
                    }}
                />  
            </Grid>
            <Grid item md={12}>
                <div className= {classes.btndiv}>
                <Button 
                    variant="contained"
                    size="small" 
                    color="primary"
                    disabled={isdirty}
                    onClick={HandleNewClick}
                >
                    New
                </Button>
                <Button 
                    variant="contained" 
                    size="small"
                    color="primary"
                    disabled={isdirty}
                    onClick={HandleEditClick}
                >
                    Edit
                </Button>
                <Button 
                    variant="contained"
                    size="small" 
                    color="primary"
                    disabled={isdirty}
                    onClick={HandleCloneClick}
                >
                    Clone
                </Button>
                <Button 
                    variant="contained" 
                    size="small"
                    color="secondary"
                    disabled={values.isinuse}
                    onClick={HandleDeleteClick}
                >
                    Delete
                </Button>
                <Button 
                    variant="contained" 
                    size="small"
                    color="primary"
                    disabled={!isdirty}
                    onClick={HandleSaveClick}
                >
                    Save
                </Button>
            </div>    
            </Grid>
            <Grid item md={12}>
            <InfoPanel msg={message} warning={warning} status={status} /> 
            </Grid>
            
        </Grid>
    </React.Fragment>    
    





)
}