//kitchen
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 12.923c-2.619-.405-4.723-2.375-5.326-4.923h-.508c-.109-.489-.166-.998-.166-1.519 0-.508.054-1.004.157-1.481h.517c.678-2.865 3.255-5 6.326-5 3.071 0 5.648 2.135 6.326 5h.517c.103.477.157.973.157 1.481 0 .521-.057 1.03-.166 1.519h-.508c-.603 2.548-2.708 4.518-5.326 4.923v11.077h-2v-11.077zm-3.472-5.923c.249 2.249 2.157 4 4.472 4 2.315 0 4.223-1.751 4.472-4h.528c.081-.301.1-.63 0-1h-.528c-.249-2.249-2.157-4-4.472-4-2.315 0-4.223 1.751-4.472 4h-.528c-.06.342-.056.675 0 1h.528zm7.254-1.817c.141.36.218.753.218 1.163 0 1.767-1.434 3.202-3.201 3.202-.279 0-.55-.036-.808-.103 2.037-.429 3.6-2.149 3.791-4.262z"/></svg>

export default function Kitchen(props) {
    const fill = props.fill || 'orangered'
    
    return (
        <svg 
            width="48" height="48" 
            xmlns="http://www.w3.org/2000/svg" 
            fill-rule="evenodd" 
            clip-rule="evenodd"
            aria-labelledby="kitchen"
        >
            <path
                fill={fill} 
                d="M11 12.923c-2.619-.405-4.723-2.375-5.326-4.923h-.508c-.109-.489-.166-.998-.166-1.519 0-.508.054-1.004.157-1.481h.517c.678-2.865 3.255-5 6.326-5 3.071 0 5.648 2.135 6.326 5h.517c.103.477.157.973.157 1.481 0 .521-.057 1.03-.166 1.519h-.508c-.603 2.548-2.708 4.518-5.326 4.923v11.077h-2v-11.077zm-3.472-5.923c.249 2.249 2.157 4 4.472 4 2.315 0 4.223-1.751 4.472-4h.528c.081-.301.1-.63 0-1h-.528c-.249-2.249-2.157-4-4.472-4-2.315 0-4.223 1.751-4.472 4h-.528c-.06.342-.056.675 0 1h.528zm7.254-1.817c.141.36.218.753.218 1.163 0 1.767-1.434 3.202-3.201 3.202-.279 0-.55-.036-.808-.103 2.037-.429 3.6-2.149 3.791-4.262z"/>
        </svg>

  
    )
  }