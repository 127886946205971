import React, { useState, useEffect, PureComponent } from 'react';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import * as Constants from '../config/constants';

const useStyles = makeStyles((theme) => ({
  category: {
    align: 'center',
    color: 'blue',
    fontWeight: '800',
    marginLeft: '30px',
    padding: '0px',
  },
  select: {
    marginLeft: '20px',
  },
  chart: {
    color: 'black'
  }  
}));

  export default function Bespoke(props) {  
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedcategory, setSelectedcategory] = useState(2);
    
    useEffect(() => {

      (async () => {

        console.log('Bespoke');
        console.log(props.selperiods[0]);
        console.log(props.selperiods[1]);
        console.log(props.selperiods[2]);
        console.log(props.selperiods[3]);
        console.log(selectedcategory);

          const result = await axios.post(Constants.API + 'DYNSTATS_Bespoke/',
          {
            param1: props.selperiods[0],
            param2: props.selperiods[1],
            param3: props.selperiods[2],
            param4: props.selperiods[3],
            param5: selectedcategory
          });
          
          setData(result.data);
          
        })();  
    
  
    }, [selectedcategory, props]);

    useEffect(() => {

      (async () => {

          const result = await axios(Constants.API + 'STATS_BespokeCategories');
          
          setCategories(result.data);
          
        })();  
    }, []);

    const handleCategoryChange = (event) => {
      const catid = event.target.value;
      setSelectedcategory(catid);
      
    };

    return (
        <div>
          <div className={classes.category}>
          <Select
            className={classes.select}
            native
            onChange={handleCategoryChange}
          >
            {categories.map(category => (
            <option
              key={category.category_id}
              value={category.category_id}
            >
              {category.category}
            </option>
            ))}   
          </Select>
          </div>
        <BarChart
          className={classes.chart}
          width={300}
          height={170}
          data={data}
          margin={{
            top: 15, right: 30, left: 10, bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Period" fontSize= {10} stroke='red' />
          <YAxis 
            yAxisId="left" 
            orientation="left" 
            stroke="#8884d8" 
            fontSize= {10} 
            label={{ fontSize: 10, stroke: 'orange', margin: 0, value: 'Daily Units', angle: -90, position: 'insideLeft' }}
          />
          <Tooltip />
          <Bar yAxisId="left" dataKey="Gr" fill="#B0C4DE" />
        </BarChart>
        </div>
      );
  }
  