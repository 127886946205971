import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InfoPanel from './infopanel';
import * as Constants from '../config/constants';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 100,
      },
    },
    maindiv: {
        display: 'block',
        paddingRight: '100px'
    },
    btndiv: {
        '& > *': {
          margin: theme.spacing(2),
        }
    }    
  }));


export default function UnlockTrn() {

    const classes = useStyles();

    const [linkid, setLinkid] = useState();
    const [messages, setMessage] = useState();
    const [status, setStatus] = useState();
    const [warning, setWarning] = useState(false);
    const [isdirty, setIsdirty] = useState(false);

    const ValidateLinkId = (event) => {
        var newvalue = event.target.value;
        if (/^(\d*\.)?\d+$/.test(newvalue))
            {
            setLinkid(newvalue);
            setMessage('');
            setWarning(false);
            setIsdirty(true);
        }    
        else {
            setMessage('Invalid character.');
            setWarning(true);
            setIsdirty(false);
        }    
    }

    const UnlockLinkId = () => {

        axios.post(Constants.API + 'unlocktrnset/', 
          {
            linkid:  linkid,
          }
        )
        .then((response) => {
            setMessage('Link id ' +  linkid + ' unlocked.');
            setWarning(false);  
            setIsdirty(false);  
          },
          (error) => {
            setMessage('Unlock failed - see log for details');
            setWarning(true);
          });
    }
    
    return(
        <React.Fragment>
        <h5>    
            <ul> 
                <li>Unlock a transaction set (set will remain unlocked for 5 minutes).</li>
                <li>Unlocked transactions sets can:</li>
                <ul>
                    <li>have portions and quantities changed.</li>
                    <li>be deleted.</li>
                </ul>
                <li>For any other changes delete the set and build a new one.</li>
            </ul>
        </h5> 
        <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.btndiv}>
                <TextField
                    id="tflinkid"
                    onChange={ValidateLinkId}
                    label="Link id"
                    size="small"
                    defaultValue="000000"
                    variant="outlined"
                />
            <Button 
                variant="contained" 
                color="primary"
                size="small"
                disabled={!isdirty}
                onClick={UnlockLinkId}
            >
                Unlock
            </Button>
            </div>
            <InfoPanel msg={messages} warning={warning} status={status} /> 
        </form>    
        </React.Fragment>
            
    )

}