import React, { useState, useEffect } from "react";
import { withStyles, createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { deepOrange, lightBlue } from "@material-ui/core/colors";
import AuthenticatedRoute from './components/authenticatedRoute';
import Switch2 from '@material-ui/core/Switch';
import LandingPage from './components/landingPage';
import Intake from './components/intake';
import Analysis from './components/analysis';
import Kitchen from './components/kitchen';
import Maintenance from './components/maintenance';
import Navigation from './components/navigation';
import * as Constants from './config/constants';

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

const TopPanel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const VersionPanel = styled.div`
  display: flex;
  width: 100%;
  align-items: right;
  font-size: 0.5em;
  justify-content: center;
`;


const ToggleDiv = styled.div`
  display: flex;
  width: 20%;
  font-size: 0.75em;
  text-align: left;
  align-items: left;
  justify-content: left;
  
`;

const SubTitle = styled.h1`
  font-size: 1.0em;
  text-align: center;
  width: 60%;
`;

const DatePanel = styled.div`
font-size: 0.75em;
text-align: center;
width: 20%;
`;

const InfoPanel = styled.div`
  display: flex;
  margin-top: 50px;
  
`;

const DBInfo = styled.div`
  margin-right: 40px;
  width: 20%;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

const CoInfo = styled.div`
  margin-right: 40px;
  width: 60%;
  color: purple;
  font-size: 1.0em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerInfo = styled.div`
  display: flex;
  font-size: 0.75em;
  width: 20%;
  align-items: end;
  justify-content: center;
  flex-direction: column;
`;

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginLeft: 10,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch2);

const App = () => {
  const [darkState, setDarkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const [date, setDate] = useState(new Date().toDateString());
  const nutraktheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: darkState ? deepOrange : lightBlue,
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
  }, 1000);
  
  return () => clearTimeout(timer);
  }, []);
  
  const themeToggler = () => {
    setDarkState(!darkState);
  }

  return (
    <ThemeProvider theme={nutraktheme}>
    <>
    <Container maxWidth="md" >
      <Typography component="div" >
      <>
        <Title>NuTrak</Title>
        <TopPanel>
          <ToggleDiv>Dark Theme
          <AntSwitch
            onChange={themeToggler}
            name="themeswitch" 
            />
          </ToggleDiv>
          <SubTitle>A tool to record and track diet</SubTitle> 
          
          <DatePanel>
            {date}
          </DatePanel>
        </TopPanel>
        <span>
             
              <VersionPanel> Version:{Constants.VER}</VersionPanel> 
        </span>
        <BrowserRouter>
          <Navigation />
          
            <Routes>
              
              <Route path="/" exact element={<LandingPage></LandingPage>} />
              <Route path="/intake" element={<Intake></Intake>} />
              <Route path="/analysis" element={<Analysis></Analysis>} />
              <Route path="/kitchen" element={<Kitchen></Kitchen>} />
              <Route path="/maintenance" element={<Maintenance></Maintenance>} />
              
            </Routes>

              
        </BrowserRouter>
        
            
          </>
          
      </Typography>  
    </Container> 
    </>
    
    </ThemeProvider>
  );
};

export default App;
