import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";

import * as Constants from  '../config/constants';
import InfoPanel from './infopanel';

const useStyles = makeStyles((theme) => ({
    btn: {
        size: 'small',
        paddingTop: 0,
      },
    }));      


class BtnCellRenderer extends React.Component {
  constructor(props) {
      super();
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
      this.props.clicked(this.props.value);
      
  }
  render() {
      return (
          
          <Tooltip title="Delete" placement="top-start">
              <span>
                  <IconButton
                      onClick={this.btnClickedHandler}
                      disabled={this.props.data.isinuse }
                      size='small'
                      color='secondary'
                      
                  >
                      <DeleteIcon />  
                  </IconButton>
              </span>
          </Tooltip> 
    )
  }
}


export default function MaintainUOM() {

    const classes = useStyles();

    const [refresh, setRefresh] = useState(false);
    const [uoms, setUoms] = useState([]);
    const [isedit, setIsedit] = useState(false);
    const [isedit2, setIsedit2] = useState(false);
    const [frameworkcomponents, setDeleteButton] 
            = useState({deletebtn: BtnCellRenderer});
    const [isdirty, setIsdirty] = useState(false);   
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState(false);
    const [status, setStatus] = useState('');
    
    

    useEffect(() => {

      var unmounted = false;
      
      if (!unmounted)
        LoadUOMs();

      return () => {
        unmounted = true;
      }  
      
    
      }, [refresh]);

    
    const LoadUOMs = () => {

      
      (async () => {
        
        const result = await axios(Constants.API + 'uoms');
        
        setUoms(result.data);
        setRefresh(false);
        
      })();      
    }


    const AddUOM = (event)=> {

      var newuom = [];

      setIsedit(true);
      setIsedit2(true);

      newuom.uom = '';
      setUoms([newuom, ...uoms]);

      setIsdirty(true);

    }

    const EditUOM = () => {

        setIsedit2(true);

    }

    const UpdateUOM = (event) => {

      var uom = event.node.data.uom;
      var description = event.node.data.description;

       (async () => {
          await axios.post(Constants.API + 'addamenduom/', 
          {
              uom:  uom,
              description:  description,
              
          } ) .then((response) => {
            setMessage('Add/amend successfull');
            setWarning(false);
            setRefresh(true);
            setIsedit(false);
            setIsedit2(false);
            setIsdirty(false);
          },
          (error) => {
            setMessage('Error on add/amend [' + error +']');
            setWarning(true);
          });
          
        })(); 
    }  
    
    
    const DeleteUOM = (field) => {

      axios.delete(Constants.API + 'deleteuom/' + field)
        .then((response) => {
            var rowsdeleted = 0;
            rowsdeleted = response.data.affectedRows;
            if (rowsdeleted > 0) {
              setMessage('UOM ' + field + ' deleted');
              setRefresh(true);
              console.log('Rows affected: '+ response.data.affectedRows);
            }
            else {
              setMessage('No records to delete');
            }
          },
          (error) => {
            setMessage('Error on delete [' + error +']');
          });
          
    } 

    
    
    return(
    
        <div>
            <div>
            <h5>    
            <ul> 
                <li>Add a new Unit of Measure</li>
                <li>Edit an existing Unit of Measure</li>
                <li>Delete an unlinked Unit of Measure</li>
            </ul>
            </h5>
            </div>
            <Button 
                    variant="contained" 
                    style={ { marginRight: 40}}
                    color="primary"
                    size="small"
                    marginRight="10px"
                    disabled={isdirty}
                    onClick={AddUOM}
            >
                Add UOM  
            </Button>
            <Button 
                    variant="contained" 
                    color="primary"
                    size="small"
                    disabled={isdirty}
                    onClick={EditUOM}
                >
                Edit UOM  
            </Button>
        <div className="ag-theme" style={ { paddingTop: 50, height: 300, width: 360 } }>
            <AgGridReact 
              onCellValueChanged={UpdateUOM}
              rowStyle= {{ fontSize: '14px',paddingLeft: '0' }}
              cell-horizontal-padding={0}
              rowSelection="single"
              rowData={uoms}
              frameworkComponents={frameworkcomponents}
              
            >
              <AgGridColumn
                width={70}
                headerName=""
                cellRendererParams = {
                    {clicked:function(field)  {
                      DeleteUOM(field);
                    },
                  }
                }
                cellRenderer = 'deletebtn'
                field="uom"
              />
              <AgGridColumn
                headerName="UOM"
                width={70}
                field="uom"
                editable={isedit}

              /> 
              <AgGridColumn
                headerName="UOM Description"
                width={200}
                field="description"
                editable={isedit2}
              /> 
                 
            </AgGridReact>
        </div> 
        <InfoPanel msg={message} warning={warning} status={status}/>
        </div>     
    
    
    )

}