import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';



export default function InfoPanel(props) {

    const InfoCard = styled(Card)`
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        
        borderRadius: '2rem';
        boxShadow: '0 6px 20px 0 #dbdbe8';
    `;
    
    const MsgInfo = styled(Card)`
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 80%;
        borderRadius: '1rem';
        font-size: 0.75em;
        color: green;
        boxShadow: '0 6px 20px 0 #dbdbe8';
    `;

    const MsgWarning = styled(Card)`
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 80%;
        borderRadius: '1rem';
        font-size: 0.75em;
        color: red;
        boxShadow: '0 6px 20px 0 #dbdbe8';
    `;

    const StatusCard = styled(Card)`
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        width: 80%;
        borderRadius: '1rem';
        font-size: 0.5em;
        boxShadow: '0 6px 20px 0 #dbdbe8';
    `;



    return (
        <React.Fragment>
        <CssBaseline />
        <InfoCard variant="outlined">
        <Grid container spacing={1}>
            <Grid item md={10}>
                {!props.warning && <MsgInfo >{props.msg}</MsgInfo>}
                {props.warning && <MsgWarning >{props.msg}</MsgWarning>}
            </Grid>
            <Grid item md={2}>
                <StatusCard >{props.status}</StatusCard>
            </Grid>
        </Grid>
        </InfoCard>
        </React.Fragment>    



        

    )

}    