import React, { useState, useEffect, PureComponent } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import * as Constants from '../config/constants';



  export default function FatsCarbs(props) {  

    const [data, setData] = useState([]);
    
    useEffect(() => {

      (async () => {

          const result = await axios.post(Constants.API + 'DYNSTATS_FatsCarbs/',
          {
            param1: props.selperiods[0],
            param2: props.selperiods[1],
            param3: props.selperiods[2],
            param4: props.selperiods[3],
          });
          
          setData(result.data);
          
        })();  
    }, [props]);

    return (
        <div fontSize={8}>
        <BarChart
          width={300}
          height={200}
          data={data}
          margin={{
            top: 15, right: 30, left: 10, bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Period" marginBottom= '30px' fontSize= {10} stroke='red' >
          <Label fontSize={16} marginTop= {10} stroke='green' value="Protein, Fat, Sats, Carbs, Sugar" offset={0} position="insideBottom" />
          </XAxis>
          <YAxis 
            yAxisId="left" 
            orientation="left" 
            stroke="#8884d8"
            fontSize= {10} 
            label={{ fontSize: 10, stroke: 'orange', margin: 0, value: 'Daily Units', angle: -90, position: 'insideLeft' }}  
          />
          <Tooltip />
         
           
          <Bar yAxisId="left" dataKey="Protein" fill="#13A055" />
          <Bar yAxisId="left" dataKey="Fat" fill="#9C13A0" />
          <Bar yAxisId="left" dataKey="Saturates" fill="#1318AO" />
          <Bar yAxisId="left" dataKey="Carbohydrates" fill="#A0131D" />
          <Bar yAxisId="left" dataKey="Sugars" fill="#8184d8" />
        </BarChart>
        </div>
      );
    //}-
  }
  