import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled, { ThemeProvider } from 'styled-components'
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import tileData from './tiledata';

import './landingpage.css';

const Attr = styled.div`
  margin: auto;
  font-size: 0.5em;
  paddingBottom: 100px;
  width: '100%';
`;

const useStyles = makeStyles((theme) => ({
  title: {
    alignContent: 'center',
    color: 'gray',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    paddingTop: 30,
  },
  gridList: {
    width: 500,
    height: 180,
  },
  
}));

export default function LandingPage() {
  const classes = useStyles();

  return (
    <React.Fragment>
    <CssBaseline />  
    <Typography component="div" style={{ background: 'transparent' }} >
    <Grid container spacing={0} height={'500px'}> 
      <Grid item md={12}>
        <div className={classes.root}>
          <GridList cellHeight={160} className={classes.gridList} rows={1} cols={4}>
            {tileData.map((tile) => (
              <GridListTile key={tile.img} cols={tile.cols || 1}>
                <img src={tile.img} alt={tile.title} />
            </GridListTile>
            ))}
          </GridList>
        </div>
      </Grid>
        <Attr>Photos by&nbsp; <a href="https://unsplash.com/@calajay?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Cala</a> on <a href="https://unsplash.com/@calajay?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></Attr>
    </Grid>
    </Typography>
    </React.Fragment>
  );
}