import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Ingredients from './ingredients';
import * as Constants from '../config/constants';

const HeaderDiv = styled.div`
  display: flex;
  font-size: 1.25em;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const MainCard = styled(Card)`
    width: 100%;
    height: 620px
`;

const MainDiv = styled.div`
    margin-left: 10px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'left',
        overflow: 'hidden',
    },
    header: {
        paddingBottom: 25,
        fontSize: 20,
        alignItems: 'center'
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
  }));

  
  export default function Brands() {

    const classes = useStyles();
    const selectInput = useRef(null);
    const [categories, setCategories] = useState( [] );
    const [brands, setBrands] = useState( [] );
    const [ingredients, setIngredients] = useState( [] );
    const [catid, setCatid] = useState(2);
    const [brandid, setBrandid] = useState(2);
    const [ingredientid, setIngredientid] = useState();
    const [nutritionaldata, setNutritionaldata] = useState();
    
    const handleCategoryChange = (event) => {
       const cat = event.target.value;
       setCatid(cat);
    };

    const handleBrandChange = (event) => {
        const brand = event.target.value;
        setBrandid(brand);
     };

     const handleCurrentValue = (event) => {
        const x = selectInput.value
     };

    const handleIngredientChange = (event) => {
        const id = event.target.value;
        setIngredientid(id);
    }; 

    // fetch categories
    useEffect(() => {
     
        (async () => {
          const result = await axios(Constants.API + 'categories/');
          var arr = [];
          arr = Object.values(result.data);
          //alert(arr.length);
          //console.log(arr);
          setCategories(arr[0]);
        })();
    }, []);

    // fetch brands
    useEffect(() => {
     
        (async () => {
            const result = await axios(Constants.API + 'brandsbycat/' + catid);
            var arr = [];
            arr = Object.values(result.data);

            setBrands(arr[0]);

            if (arr.length > 0) {
                //setBrandid(result.data[0].brand_id);
                //alert(arr[0][0].brand_id);
                setBrandid(arr[0][0].brand_id);
            }
        })();
    }, [catid]);

    // fetch ingredients
    useEffect(() => {
     
        (async () => {
          const result = await axios.post(Constants.API + 'ingredientsbybrandandcategory/',
            {
                param1:  brandid,
                param2:  catid
            } )

            var arr = [];
            arr = Object.values(result.data);

            setIngredients(arr[0]);

            if (arr[0].length > 0) {    
                setIngredientid(arr[0][0].ingredient_id);
            }

        })();
    }, [brandid, catid]);

   
    useEffect(() => {

        var i = 0;
        
        for (i = 0; ingredients.length > i; i += 1) {
            
            if (ingredients[i].ingredient_id == ingredientid) {
                setNutritionaldata(ingredients[i]);
                break
          }
        }  
    }, [ingredientid, ingredients]);
    

    return (

    <React.Fragment>
        <CssBaseline />
        <Container style={{ backgroundColor: 'transparent'}} maxWidth="md">
            <Typography component="div" 
                style={{ backgroundColor: 'transparent', height: '100vh' }} >
                <MainCard variant="outlined">
                <Grid  container spacing={3}>
                    <Grid  item xs={12}>
                    <div className={classes.root}>
                        <HeaderDiv>
                        <Box className= {classes.header}>Ingredient selection</Box>
                        </HeaderDiv>
                        <MainDiv>
                        <FormControl className={classes.formControl}>
                            <Select
                                native
                                onChange={handleCategoryChange}
                                >
                                {categories.map(category => (
                                    <option
                                        key={category.category_id}
                                        value={category.category_id}
                                    >
                                        {category.category}
                                    </option>
                                ))}   
                            </Select>
                        </FormControl>
                        </MainDiv>
                    </div>      
                    </Grid>
                    <Grid item xs={12}>
                    <div className={classes.root}>
                        <MainDiv>
                        <FormControl className={classes.formControl}>
                            <Select
                                native
                                onChange={handleBrandChange}
                                ref={selectInput}
                                onBlur={handleCurrentValue}
                                >
                                {brands.map(brand => (
                                    <option
                                        key={brand.brand_id}
                                        value={brand.brand_id}
                                    >
                                        {brand.brand}
                                    </option>
                                ))}   
                            </Select>
                        </FormControl>
                        </MainDiv>
                        </div>   
                    </Grid>
                    <Grid item xs={12}>
                    <div className={classes.root}>
                        <MainDiv>
                        <FormControl className={classes.formControl}>
                            <Select
                                native
                                disabled={!ingredients}
                                onChange={handleIngredientChange}
                                >
                                {ingredients.map(ingredient => (
                                    <option
                                        key={ingredient.ingredient_id}
                                        value={ingredient.ingredient_id}
                                    >
                                        {ingredient.ingredient}
                                    </option>
                                ))}   
                            </Select>
                        </FormControl>
                        </MainDiv>
                        </div>    
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Ingredients val={nutritionaldata} />
                        </div>
                    </Grid>
                </Grid>
                </MainCard>
            </Typography>  
        </Container>
    </React.Fragment>
    );
}