export default function Edit(props) {
    const fill = props.fill || 'orangered';
    const width = '36';
    const height = '36';
    
    return (

            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width} height={height}  
                viewBox="0 0 36 36"
            >
                <path
                    fill={fill}
                    d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z"/>
            </svg>
    )
    }            
