export default function Exit(props) {
  const fill = props.fill || 'orangered'
  
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="48" height="48" 
        viewBox="0 0 48 48"
        aria-labelledby="exit">    
        <path fill={fill}
            d="M0 2v20h14v-2h-12v-16h12v-2h-14zm18 7.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7z"/>
        </svg>

  )
}